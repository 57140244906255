// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    reprocessing: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingDataAuditChangesResponseDto | undefined;

    refreshReprocessingDataAuditChanges(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingDataAuditChangesResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    public reprocessing: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingDataAuditChangesResponseDto | undefined;

    public refreshReprocessingDataAuditChanges(response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingDataAuditChangesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reprocessing = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.reprocessing = clone(this.reprocessing);
        return vm;
    }
}
