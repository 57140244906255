// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import ProjectReportAttachmentsView from "./views/ReprocessingProjectReportAttachmentsView";
import { GetGeoscienceAustraliaReportAttachmentsItemDto } from "../../../../../api/Client";
// common
import * as documentHelper from "../../../../../common/documentHelper";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";

interface IRouteParameters {
    reprocessingId: string;
    reprocessingProjectId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { reprocessingId, reprocessingProjectId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(reprocessingProjectId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function onEditHandle(reportId: number, reportVersionNumber: number, reportAttachmentId: number, documentLink: string, fileName: string) {
        actions.updateReportAttachmentRequested(reportId, reportVersionNumber, reportAttachmentId, documentLink, fileName);
    }

    function onDeleteHandle(reportId: number, reportVersionNumber: number, reportAttachmentId: number) {
        actions.deleteReportAttachmentRequested(reportId, reportVersionNumber, reportAttachmentId);
    }

    function onBatchDownloadHandle(reportAttachments: GetGeoscienceAustraliaReportAttachmentsItemDto[]) {
        documentHelper.multipleDownloads(
            reportAttachments.map((attachment) => {
                return { url: attachment.documentLink!, fileName: attachment.fileName! };
            })
        );
    }

    return (
        <>
            {vm.attachments && vm.reprocessingProjectName && (
                <BodyLayout
                    title={`Project - ${vm.reprocessingProjectName}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjects}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(reprocessingProjectId));
                    }}
                >
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <NavBar reprocessingId={Number(reprocessingId)} reprocessingProjectId={Number(reprocessingProjectId)} activeNavItem={TabsEnum.Attachments} />
                    <ProjectReportAttachmentsView vm={vm} onDelete={onDeleteHandle} onEdit={onEditHandle} onBatchDownload={onBatchDownloadHandle} />
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectReportAttachments }), Actions.actionFactory)(Controller);
