// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// common
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import SurveyDataListGridView from "./views/SurveyDataListGridView";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";

interface IRouteParameters {
    reprocessingId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { reprocessingId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(reprocessingId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {vm.reprocessingName && vm.reprocessingProjects && (
                <BodyLayout
                    title={`Reprocessing - ${vm.reprocessingName}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_Reprocessings}
                    onReloadRequested={() => {
                        props.clearRequested();
                        props.initialiseRequested(Number(reprocessingId));
                    }}
                >
                    <NavBar reprocessingId={Number(reprocessingId)} activeNavItem={TabsEnum.SurveyData} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <DirtyScope scopeName="geoscienceAustralia/reprocessingProjects/reprocessing/surveyData" isDirty={vm.isDirty}></DirtyScope>
                    <LinksView
                        title="Project(s)"
                        canViewLink={true}
                        linkTooltip="Click to view Project Survey Data"
                        links={vm.reprocessingProjects.map(
                            (reprocessingProject) => new LinkDetail(reprocessingProject.name, GeoscienceAustraliaRouteHelper.reprocessingProjectSurveyData(vm.reprocessingId!, reprocessingProject.id))
                        )}
                    />
                    <SurveyDataListGridView vm={vm} />
                    {/*
                    <div className="container-fluid p-2 form">
                        <div className="mt-3">
                             {vm.viewState === Models.ViewStateEnum.View && (
                                <>
                                    {vm.surveyData.length > 0 && (
                                        <button className="btn btn-primary me-2" onClick={() => onSelectBatchUpdate()}>
                                            Batch Update
                                        </button>
                                    )}
                                    <button type="button" className="btn btn-primary me-2" onClick={onCreate}>
                                        Create Survey Data
                                    </button>
                                </>
                            )}
                            {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && (
                                <>
                                    <button
                                        className="btn btn-primary me-2"
                                        disabled={!vm.surveyData.some((item) => item.isSelected) || vm.surveyData.filter((item) => item.isSelected).length > 100}
                                        onClick={() => onEditBatchUpdate()}
                                    >
                                        Confirm Survey Data
                                    </button>
                                    <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested(Number(reprocessingId))}>
                                        Cancel
                                    </button>
                                </>
                            )}
                            {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && (
                                <>
                                    <button className="btn btn-primary me-2" onClick={() => onBatchUpdate()}>
                                        Batch Update
                                    </button>
                                    <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested(Number(reprocessingId))}>
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </div> */}
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReprocessingsProjects_ReprocessingSurveyData }), Actions.actionFactory)(Controller);
