import { useState } from "react";
import { orderBy } from "@progress/kendo-data-query";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import * as Client from "../../api/Client";
import * as GlobalHelpers from "../GlobalHelpers";
import SimpleAlertView from "../simpleAlerts/SimpleAlertView";
import SimpleDataAuditChangeView from "./controls/SimpleDataAuditChangeView";

interface IProps {
    className?: string | undefined;
    changes: Client.SecurePortalSimpleDataAuditChangesDto | undefined;
}

export default function SimpleDataAuditChangesView(props: IProps) {
    const [selectedIndex, setSelectedIndex] = useState(-1);

    if (!props?.changes?.changes) return <></>;
    if (props.changes.changes.length === 0)
        return (
            <>
                <div className={props.className}>
                    <SimpleAlertView alertType="information" dismissable={false} message="No auditing information available." />
                </div>
            </>
        );

    const changes = orderBy(props.changes!.changes!, [{ field: "timestamp", dir: "desc" }]);

    return (
        <div className={props.className}>
            {changes.map((change, index) => {
                const timestamp = GlobalHelpers.toNoptaDatetimeString(change.timestamp);
                let message;
                if (!change.identity) message = `${timestamp} (AWST)`;
                else message = `${change.identity} on ${timestamp} (AWST)`;

                return (
                    <ExpansionPanel
                        key={index.toString()}
                        title={message}
                        expanded={selectedIndex === index}
                        onAction={(event) => {
                            setSelectedIndex(event.expanded ? -1 : index);
                        }}
                    >
                        <Reveal>
                            {selectedIndex === index && (
                                <ExpansionPanelContent>
                                    <SimpleDataAuditChangeView change={change} />
                                </ExpansionPanelContent>
                            )}
                        </Reveal>
                    </ExpansionPanel>
                );
            })}
        </div>
    );
}
