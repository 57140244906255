// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_WellsBoreholes_WellReportAttachments;

export default function* updateReportAttachment(action: Actions.IAction<Actions.IUpdateReportAttachmentRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { reportId, reportVersionNumber: wellVersionNumber, reportAttachmentId, documentLink, fileName } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());
        const saveResult: CallHelper.CallResult<Client.UpdateGeoscienceAustraliaReportAttachmentResponseDto> = yield call(
            async () => await updateWellReportAttachment(reportId, wellVersionNumber, reportAttachmentId, documentLink, fileName)
        );
        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const saveResponse = saveResult.Response!;
            if (saveResponse.statusMessages!.isSuccess) {
                const getAttachmentsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaWellReportAttachmentsResponseDto> = yield call(async () => await getReportAttachments(vm.wellId!));
                updatedVm = vm.refreshWellReportAttachments(getAttachmentsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshStatusMessages(saveResponse.statusMessages!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict(true)));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function updateWellReportAttachment(
    reportId: number,
    reportVersionNumber: number,
    reportAttachmentId: number,
    documentLink: string,
    fileName: string
): Promise<CallHelper.CallResult<Client.UpdateGeoscienceAustraliaReportAttachmentResponseDto>> {
    const request = new Client.UpdateGeoscienceAustraliaReportAttachmentRequestDto({
        reportId: reportId,
        reportVersionNumber: reportVersionNumber,
        reportAttachmentId: reportAttachmentId,
        documentLink: documentLink,
        fileName: fileName,
    });
    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.updateGeoscienceAustraliaReportAttachment(request));
}

async function getReportAttachments(wellId: number): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaWellReportAttachmentsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaWellReportAttachmentsRequestDto({
        wellId: wellId,
    });
    const client = await ClientFactory.createGeoscienceAustraliaReportClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaWellReportAttachments(request));
}
