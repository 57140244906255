// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

/**
 * Used to create or update surveyData attachments
 */
export interface ISaveSurveyDataAttachment {
    surveyDataAttachmentId?: number | undefined;
    fileName?: string;
    documentLink?: string;
}

export interface IRootViewModel {
    surveyDataId: number | undefined;
    surveyDataVersionNumber: number | undefined;
    publicTitle: string | undefined;
    itemId: string | undefined;
    pageHeader: string | undefined;
    saveSurveyDataAttachment?: ISaveSurveyDataAttachment;

    Attachments: Client.GetGeoscienceAustraliaSurveyDataAttachmentsItemDto[] | undefined;

    isConflict: boolean;
    isDirty: boolean;
    statusMessages?: Client.StatusMessagesDto;

    refreshAttachments(response: Client.GetGeoscienceAustraliaSurveyDataAttachmentsResponseDto): IRootViewModel;
    refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
    onSaveSurveyDataAttachmentChanged(saveSurveyDataAttachment: ISaveSurveyDataAttachment): IRootViewModel;
    refreshConflict(isConflict: boolean): IRootViewModel;
    clearStatusMessages(): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyDataId = undefined;
        this.surveyDataVersionNumber = undefined;
        this.publicTitle = undefined;
        this.itemId = undefined;
        this.pageHeader = undefined;

        this.saveSurveyDataAttachment = this.defaultSaveSurveyDataAttachment();

        this.Attachments = [];

        this.isConflict = false;
        this.isDirty = false;
    }

    public surveyDataId: number | undefined;
    public surveyDataVersionNumber: number | undefined;
    public publicTitle: string | undefined;
    public itemId: string | undefined;
    public pageHeader: string | undefined;
    public saveSurveyDataAttachment: ISaveSurveyDataAttachment;

    public Attachments: Client.GetGeoscienceAustraliaSurveyDataAttachmentsItemDto[] | undefined;

    public isDirty: boolean;
    public isConflict: boolean;
    public statusMessages?: Client.StatusMessagesDto;

    public refreshAttachments(response: Client.GetGeoscienceAustraliaSurveyDataAttachmentsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyDataId = response.surveyDataId;
        vm.surveyDataVersionNumber = response.surveyDataVersionNumber;
        vm.publicTitle = response.publicTitle;
        vm.itemId = response.itemId;
        vm.pageHeader = response.surveyDataHeader;

        vm.Attachments = response.attachments;

        return vm;
    }

    public refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = statusMessages;
        return vm;
    }

    public refreshConflict(isConflict: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = isConflict;
        return vm;
    }

    public onSaveSurveyDataAttachmentChanged(saveSurveyDataAttachment: ISaveSurveyDataAttachment): IRootViewModel {
        const vm = this._clone();
        vm.saveSurveyDataAttachment = saveSurveyDataAttachment;
        vm.isDirty = true;
        return vm;
    }

    public clearStatusMessages(): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.saveSurveyDataAttachment = this.defaultSaveSurveyDataAttachment();
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.statusMessages = undefined;
        return vm;
    }

    private defaultSaveSurveyDataAttachment(): ISaveSurveyDataAttachment {
        return {
            surveyDataAttachmentId: undefined,
            fileName: undefined,
            documentLink: undefined,
        };
    }
}
