// framework
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// models
import { IAttachmentItemViewModel, IRootViewModel } from "../../models/models";
// api
import { AttachmentTypeEnum, GetGeoscienceAustraliaAttachmentListRelatedActivityDto, RelatedActivityEnum } from "../../../../../../api/Client";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import ListSummaryView, { Orientation } from "../../../../../../common/listSummary/listSummaryView";

export default function AttachmentListGridView(props: { vm: IRootViewModel; showBatchDownload: boolean; onSelectionChanged: (attachment: IAttachmentItemViewModel) => void }): JSX.Element {
    const { vm, showBatchDownload, onSelectionChanged } = props;

    const [attachmentItemsState, setAttachmentItemsState] = useState(new Array<IAttachmentItemViewModel>());

    const pageSize: number = 20;
    const initialState: State = {
        sort: [
            { field: "dateLastModified", dir: "desc" },
            { field: "uploadedDate", dir: "desc" },
        ],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: pageSize,
    };

    const [gridState, setGridState] = useState(initialState);
    const [dataResult, setDataResult] = useState(process(attachmentItemsState, gridState));
    const [isUpdatingItem, setIsUpdatingItem] = useState(false);

    // reset grid state when data changes
    useEffect(() => {
        if (isUpdatingItem) {
            setIsUpdatingItem(false);
            setAttachmentItemsState(vm.attachments);
            setDataResult(process(vm.attachments, gridState));
            return;
        }

        setGridState(initialState);
        setAttachmentItemsState(vm.attachments);
        setDataResult(process(vm.attachments, initialState));
    }, [vm.attachments]); // eslint-disable-line react-hooks/exhaustive-deps

    function onCheckedChanged(item: IAttachmentItemViewModel, newValue: boolean) {
        setIsUpdatingItem(true);
        // find the selected/deselected attachment and update its selected state accordingly
        const updatedItemsState = dataResult?.data.map((r) => {
            if (r.id !== item.id) return r;
            return { ...r, isSelected: newValue };
        });

        // raise event with the selected attachment and it's new values
        onSelectionChanged({ ...item, isSelected: newValue });

        setDataResult({ data: updatedItemsState, total: dataResult.total });
    }

    function generatePopupViewEntry(canView: boolean, relatedActivityName: string, activity: GetGeoscienceAustraliaAttachmentListRelatedActivityDto, url: string): ReactElement {
        return canView ? (
            <div className="row">
                <div className="col-sm-2">{relatedActivityName}</div>
                <div className="col-sm-10">
                    <Link to={url} className={`form-link${activity.selectedInAdvancedSearch ? " k-font-weight-bold" : ""}`} title={`Click to view ${relatedActivityName} Attachments.`}>
                        {activity.identifier} - {activity.name}
                    </Link>
                </div>
            </div>
        ) : (
            <span className={`${activity.selectedInAdvancedSearch ? "k-font-weight-bold" : ""}`}>{activity.identifier}</span>
        );
    }

    function generateSummaryViewEntry(canView: boolean, relatedActivityName: string, activity: GetGeoscienceAustraliaAttachmentListRelatedActivityDto, url: string): ReactElement {
        return canView ? (
            <Link to={url} className={`form-link${activity.selectedInAdvancedSearch ? " k-font-weight-bold" : ""}`} title={`Click to view ${relatedActivityName} Attachments.`}>
                {activity.identifier}
            </Link>
        ) : (
            <span className={`${activity.selectedInAdvancedSearch ? "k-font-weight-bold" : ""}`}>{activity.identifier}</span>
        );
    }

    function generateSummaryView(activity: GetGeoscienceAustraliaAttachmentListRelatedActivityDto, index: number): ReactElement {
        return (
            <span key={`RelatedActivitySummaryEntry${index}`}>
                {activity.type === RelatedActivityEnum.Acquisition &&
                    generateSummaryViewEntry(
                        vm.canViewAcquisitions,
                        "Acquisition",
                        activity,
                        GeoscienceAustraliaRouteHelper.acquisitionAttachments(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}

                {activity.type === RelatedActivityEnum.Borehole &&
                    generateSummaryViewEntry(
                        vm.canViewBoreholes,
                        "Borehole",
                        activity,
                        GeoscienceAustraliaRouteHelper.boreholeAttachments(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}

                {activity.type === RelatedActivityEnum.Project &&
                    generateSummaryViewEntry(
                        vm.canViewProjects,
                        "Project",
                        activity,
                        GeoscienceAustraliaRouteHelper.reprocessingProjectAttachments(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}
            </span>
        );
    }

    function generatePopupView(activity: GetGeoscienceAustraliaAttachmentListRelatedActivityDto, index: number): ReactElement {
        return (
            <span key={`RelatedActivityPopupEntry${index}`}>
                {activity.type === RelatedActivityEnum.Acquisition &&
                    generatePopupViewEntry(
                        vm.canViewAcquisitions,
                        "Acquisition",
                        activity,
                        GeoscienceAustraliaRouteHelper.acquisitionAttachments(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}

                {activity.type === RelatedActivityEnum.Borehole &&
                    generatePopupViewEntry(vm.canViewBoreholes, "Borehole", activity, GeoscienceAustraliaRouteHelper.boreholeAttachments(activity.relatedActivityParentId, activity.relatedActivityId))}

                {activity.type === RelatedActivityEnum.Project &&
                    generatePopupViewEntry(
                        vm.canViewProjects,
                        "Project",
                        activity,
                        GeoscienceAustraliaRouteHelper.reprocessingProjectAttachments(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}
            </span>
        );
    }

    return (
        <>
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={dataResult}
                onDataStateChange={(e) => {
                    if (e.dataState.sort) {
                        e.dataState.sort = e.dataState.sort.map((sort) => {
                            if (sort.field === "bulkLoadNumber") {
                                return {
                                    ...sort,
                                    compare: (a: { bulkLoadNumber: string }, b: { bulkLoadNumber: string }) => {
                                        const aParsedIntValue = parseInt(a.bulkLoadNumber);
                                        const bParsedIntValue = parseInt(b.bulkLoadNumber);

                                        let sortResultValue = 0;
                                        if (aParsedIntValue < bParsedIntValue || (isNaN(aParsedIntValue) && !isNaN(bParsedIntValue))) sortResultValue = -1;
                                        if (aParsedIntValue > bParsedIntValue || (!isNaN(aParsedIntValue) && isNaN(bParsedIntValue))) sortResultValue = 1;

                                        if (sort.dir === "asc") return sortResultValue;
                                        else return sortResultValue * -1;
                                    },
                                };
                            }
                            return sort;
                        });
                    }
                    setGridState(e.dataState);
                    setDataResult(process(attachmentItemsState, e.dataState));
                }}
                {...gridState}
            >
                {showBatchDownload && (
                    <GridColumn
                        title=""
                        width={32}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <input
                                    type="checkbox"
                                    className={Forms.CheckInputClass}
                                    id={`checkbox${props.dataItem.itemId}`}
                                    checked={props.dataItem.isSelected}
                                    onChange={() => onCheckedChanged(props.dataItem, !props.dataItem.isSelected)}
                                />
                            </CustomGridCell>
                        )}
                    />
                )}
                <GridColumn field="attachmentTypeName" title="Type" width={140} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.attachments)} />
                <GridColumn
                    field="attachmentTypeItemId"
                    title="Item ID"
                    width={140}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.attachmentType.value === AttachmentTypeEnum.Report ? (
                                <Link to={GeoscienceAustraliaRouteHelper.reportDetails(props.dataItem.attachmentTypeId)} title="Click to view Report Details.">
                                    {props.dataItem.attachmentTypeItemId}
                                </Link>
                            ) : (
                                <Link to={GeoscienceAustraliaRouteHelper.surveyDataDetails(props.dataItem.attachmentTypeId)} title="Click to view Survey Data Details.">
                                    {props.dataItem.attachmentTypeItemId}
                                </Link>
                            )}
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn
                    field="relatedActivitiesString"
                    title="Related Activities"
                    width={450}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.relatedActivities && props.dataItem.relatedActivities.length > 0 && (
                                <ListSummaryView
                                    title="Related Activities"
                                    popupListOrientation={Orientation.Vertical}
                                    maxChildrenDisplayCount={6}
                                    children={props.dataItem.relatedActivities?.map((activity: GetGeoscienceAustraliaAttachmentListRelatedActivityDto, index: number) => {
                                        return { displayElement: generateSummaryView(activity, index), popupDisplayElement: generatePopupView(activity, index) };
                                    })}
                                ></ListSummaryView>
                            )}
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={!showBatchDownload ? createGridColumnFilterMenu : undefined}
                />
                <GridColumn field="fileName" title="File Name" width={190} filter="text" columnMenu={createGridColumnFilterMenu} />
                <GridColumn
                    field="documentLink"
                    title="Document Link"
                    width={540}
                    filter="text"
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <ExternalLinkView className="text-nowrap" href={props.dataItem.documentLink}>
                                {props.dataItem.documentLink}
                            </ExternalLinkView>
                        </CustomGridCell>
                    )}
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn
                    field="fileSize"
                    title="File Size"
                    width={140}
                    cell={(props) => <CustomGridCell gridCellProps={props}>{GlobalHelpers.convertBytesToString(props.dataItem.fileSize)}</CustomGridCell>}
                />
                <GridColumn
                    field="numberOfMappings"
                    title="Total Mappings"
                    width={240}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.attachmentType.value === AttachmentTypeEnum.Report ? (
                                vm.canViewReports ? (
                                    <Link to={GeoscienceAustraliaRouteHelper.reportRelatedActivities(props.dataItem.attachmentTypeId)} title="Click to view Report Related Activities.">
                                        {props.dataItem.numberOfMappings}
                                    </Link>
                                ) : (
                                    <>{props.dataItem.numberOfMappings}</>
                                )
                            ) : vm.canViewSurveyData ? (
                                <Link to={GeoscienceAustraliaRouteHelper.surveyDataRelatedActivities(props.dataItem.attachmentTypeId)} title="Click to view Survey Data Related Activities.">
                                    {props.dataItem.numberOfMappings}
                                </Link>
                            ) : (
                                <>{props.dataItem.numberOfMappings}</>
                            )}
                        </CustomGridCell>
                    )}
                    filter="numeric"
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn field="uploadedDate" title="Uploaded Date" width={210} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="uploadedBy" title="Uploaded By" width={190} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.attachments)} />
                <GridColumn field="bulkLoadNumber" title="Bulk Import ID" width={210} filter="numeric" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.attachments)} />
                <GridColumn field="dateLastModified" title="Last Modified Date" width={230} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
                {/*ToDo: WBR01-09*/}
                {/* <GridColumn
                    width={160}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button className="btn btn-secondary btn-sm" type="button" title="Click to edit Attachment." disabled={true} onClick={() => {}}>
                                Edit
                            </button>
                            <button className="btn btn-secondary btn-sm mx-2" type="button" title="Click to delete Attachment." disabled={true} onClick={() => {}}>
                                Delete
                            </button>
                        </CustomGridCell>
                    )}
                /> */}
            </Grid>
        </>
    );
}
