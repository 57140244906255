// framework
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { ISurveyDataItemViewModel, IRootViewModel, ViewStateEnum } from "../../models/models";
// common
import * as Client from "../../../../../../api/Client";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";

export default function SurveyDataListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const [surveyDataItemsState, setSurveyDataItemsState] = useState(new Array<ISurveyDataItemViewModel>());

    const initialState: State = {
        sort: [
            { field: "reprocessingProjectDescriptions", dir: "asc" },
            { field: "receivedDate", dir: "desc" },
        ],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);

    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.surveyData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        switch (vm.viewState) {
            case ViewStateEnum.View:
                setSurveyDataItemsState(vm.surveyData);
                setGridState(gridState);
                break;
        }
    }, [vm.viewState, vm.surveyData, gridState]);

    const rowRender = (trElement: any, props: any) => {
        const failedStyle = {
            backgroundColor: "#F8D7DA",
        };
        const trProps = {
            style: props.dataItem.updateFailed ? failedStyle : undefined,
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };

    return (
        <>
            <Grid
                pageable
                sortable={true}
                resizable
                navigatable
                rowRender={rowRender}
                data={process(surveyDataItemsState, gridState)}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
                {...gridState}
            >
                <GridColumn
                    field="reprocessingProjectDescriptions"
                    title="Project(s)"
                    width={240}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.reprocessingProjects.map((project: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataReprocessingProjectItemDto, index: number) => (
                                <span key={project.id}>
                                    <Link to={GeoscienceAustraliaRouteHelper.reprocessingProjectSurveyData(vm.reprocessingId!, project.id)} title="Click to view Project Survey Data.">
                                        {project.name}
                                    </Link>
                                    <span>{index === props.dataItem.reprocessingProjects.length - 1 ? <></> : ", "}</span>
                                </span>
                            ))}
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)}
                />

                <GridColumn
                    field="itemId"
                    title="Item ID"
                    width={140}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={GeoscienceAustraliaRouteHelper.surveyDataDetails(props.dataItem.id)} title="Click to view Survey Data Details.">
                                {props.dataItem.itemId}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn title="Title" field="title" width={500} columnMenu={createGridColumnFilterMenu} sortable={false} />
                <GridColumn field="dataType" title="Data Type" width={240} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />
                <GridColumn field="tapeId" title="Tape ID" width={190} filter="text" columnMenu={createGridColumnFilterMenu} />

                <GridColumn field="receivedDate" title="Received Date" width={220} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />

                <GridColumn field="isConfidential" title="Confidentiality" width={190} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />

                <GridColumn
                    title="Confidentiality Remarks"
                    width={260}
                    sortable={false}
                    field="confidentialityRemarks"
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.confidentialityRemarks} maxSummaryLength={20} title="Confidentiality Remarks"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />

                <GridColumn field="conditionStatus" title="Condition Status" width={200} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />

                <GridColumn field="loanStatus" title="Loan Status" width={170} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />

                <GridColumn
                    title="Consignment"
                    width={250}
                    sortable={false}
                    field="consignment"
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.consignment} maxSummaryLength={20} title="Consignment"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
