import * as Client from "../../../api/Client";

interface IProps {
    originalAttributes?: Client.DeltaModelAttributeDto[] | undefined;
    newAttributes?: Client.DeltaModelAttributeDto[] | undefined;
}

export default function SimpleDataAuditDeltaAttributesView(props: IProps) {
    if (!props.originalAttributes || !props.newAttributes || (props.originalAttributes.length === 0 && props.newAttributes.length === 0)) return <></>;

    const originalAttributes = props.originalAttributes;
    const newAttributes = props.newAttributes;
    const attributeNames = [...new Set(originalAttributes.map((a) => a.name).concat(newAttributes.map((a) => a.name)))].sort();

    return (
        <>
            <div className="table-responsive">
                <table className="table table-bordered table-striped">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" className="w-25">
                                Attribute
                            </th>
                            <th scope="col" className="w-25">
                                Original Value
                            </th>
                            <th scope="col" className="w-25">
                                New Value
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-light">
                        {attributeNames.map((attributeName, i) => {
                            let originalAttribute = originalAttributes.find((a) => a.name === attributeName);
                            let newAttribute = newAttributes.find((a) => a.name === attributeName);
                            return (
                                <tr key={i.toString()}>
                                    <td>{attributeName}</td>
                                    <td>{originalAttribute?.value}</td>
                                    <td>{newAttribute?.value}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}
