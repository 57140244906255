// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    Projects,
    Titles,
    Acquisitions,
    DataMonitoring,
    SurveyData,
    Reports,
    Attachments,
    Auditing,
}

export interface IProps {
    reprocessingId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.reprocessingDetails(props.reprocessingId),
                },
                {
                    key: TabsEnum.Projects,
                    title: "Projects",
                    url: geoscienceAustraliaRouteHelper.reprocessingProjects(props.reprocessingId),
                },
                {
                    key: TabsEnum.Titles,
                    title: "Title Relationships",
                    url: geoscienceAustraliaRouteHelper.reprocessingTitles(props.reprocessingId),
                },
                {
                    key: TabsEnum.Acquisitions,
                    title: "Related Acquisitions",
                    url: geoscienceAustraliaRouteHelper.reprocessingAcquisitions(props.reprocessingId),
                },
                {
                    key: TabsEnum.DataMonitoring,
                    title: "Data Monitoring",
                    url: geoscienceAustraliaRouteHelper.reprocessingDataMonitoring(props.reprocessingId),
                },
                {
                    key: TabsEnum.SurveyData,
                    title: "Survey Data",
                    url: geoscienceAustraliaRouteHelper.reprocessingSurveyData(props.reprocessingId),
                },
                {
                    key: TabsEnum.Reports,
                    title: "Reports",
                    url: geoscienceAustraliaRouteHelper.reprocessingReports(props.reprocessingId),
                },
                {
                    key: TabsEnum.Attachments,
                    title: "Attachments",
                    url: geoscienceAustraliaRouteHelper.reprocessingAttachments(props.reprocessingId),
                },
                {
                    key: TabsEnum.Auditing,
                    title: "Auditing",
                    url: geoscienceAustraliaRouteHelper.reprocessingAuditing(props.reprocessingId),
                },
            ]}
        />
    );
}
