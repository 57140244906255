// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    survey: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataAuditChangesResponseDto | undefined;

    refreshSurveyDataAuditChanges(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataAuditChangesResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    public survey: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataAuditChangesResponseDto | undefined;

    public refreshSurveyDataAuditChanges(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyDataAuditChangesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.survey = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.survey = clone(this.survey);
        return vm;
    }
}
