// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataAttachments;

export default function* update() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.CreateGeoscienceAustraliaSurveyDataAttachmentResponseDto> = yield call(async () => await createSurveyDataAttachment(vm));

        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = saveResult.Response!;
            if (r.statusMessages.isSuccess) {
                const getAttachmentsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataAttachmentsResponseDto> = yield call(async () => await getSurveyDataAttachments(vm));
                updatedVm = vm.refreshAttachments(getAttachmentsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshStatusMessages(saveResult.Response!.statusMessages)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict(true)));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function createSurveyDataAttachment(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.CreateGeoscienceAustraliaSurveyDataAttachmentResponseDto>> {
    const request = new Client.CreateGeoscienceAustraliaSurveyDataAttachmentRequestDto({
        surveyDataId: vm.surveyDataId!,
        surveyDataVersionNumber: vm.surveyDataVersionNumber!,
        documentLink: vm.saveSurveyDataAttachment?.documentLink,
        fileName: vm.saveSurveyDataAttachment?.fileName,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.createGeoscienceAustraliaSurveyDataAttachment(request));
}

async function getSurveyDataAttachments(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataAttachmentsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveyDataAttachmentsRequestDto({
        surveyDataId: vm.surveyDataId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveyDataAttachments(request));
}
