// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IAddSurveyDataRelatedActivities {
    activityType?: Client.SurveyDataRelatedActivityTypeEnum | undefined;
    activities: Client.GetGeoscienceAustraliaSurveyDataRelatedActivityReferenceDataDto[];
    comments?: string | undefined;
}

export interface IRootViewModel {
    surveyDataId: number | undefined;
    surveyDataVersionNumber: number | undefined;
    publicTitle: string | undefined;
    itemId: string | undefined;
    pageHeader: string | undefined;

    relatedActivities: Client.GetGeoscienceAustraliaSurveyDataRelatedActivityDto[];

    referenceData: {
        relatedActivities: Client.GetGeoscienceAustraliaSurveyDataRelatedActivityReferenceDataDto[];
        allowableActivityTypes: Client.SurveyDataRelatedActivityTypeEnum[];
    };

    addRelatedActivities: IAddSurveyDataRelatedActivities;

    statusMessages?: Client.StatusMessagesDto;

    isDirty: boolean;
    isConflict: boolean;

    refreshRelatedActivities(response: Client.GetGeoscienceAustraliaSurveyDataRelatedActivitiesResponseDto): IRootViewModel;

    refreshAddResponse(response: Client.AddGeoscienceAustraliaSurveyDataRelatedActivitiesResponseDto): IRootViewModel;
    refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaSurveyDataRelatedActivityResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
    clearStatusMessages(): IRootViewModel;

    onAddRelatedActivitiesChanged(addRelatedActivities: IAddSurveyDataRelatedActivities): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyDataId = undefined;
        this.surveyDataVersionNumber = undefined;
        this.publicTitle = undefined;
        this.itemId = undefined;
        this.pageHeader = undefined;

        this.relatedActivities = [];
        this.referenceData = {
            relatedActivities: [],
            allowableActivityTypes: [],
        };

        this.addRelatedActivities = {
            activityType: undefined,
            activities: [],
            comments: undefined,
        };

        this.isDirty = false;
        this.isConflict = false;
    }

    public surveyDataId: number | undefined;
    public surveyDataVersionNumber: number | undefined;
    public publicTitle: string | undefined;
    public itemId: string | undefined;
    public pageHeader: string | undefined;

    public relatedActivities: Client.GetGeoscienceAustraliaSurveyDataRelatedActivityDto[];

    public referenceData: {
        relatedActivities: Client.GetGeoscienceAustraliaSurveyDataRelatedActivityReferenceDataDto[];
        allowableActivityTypes: Client.SurveyDataRelatedActivityTypeEnum[];
    };

    public addRelatedActivities: IAddSurveyDataRelatedActivities;

    public statusMessages?: Client.StatusMessagesDto;

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshRelatedActivities(response: Client.GetGeoscienceAustraliaSurveyDataRelatedActivitiesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyDataId = response.surveyDataId;
        vm.surveyDataVersionNumber = response.surveyDataVersionNumber;
        vm.publicTitle = response.publicTitle;
        vm.itemId = response.itemId;
        vm.pageHeader = response.surveyDataHeader;

        vm.relatedActivities = response.relatedActivities;
        vm.referenceData = {
            relatedActivities: response.relatedActivityReferenceData,
            allowableActivityTypes: [Client.SurveyDataRelatedActivityTypeEnum.Acquisition, Client.SurveyDataRelatedActivityTypeEnum.Project],
        };

        vm.addRelatedActivities = {
            activityType: undefined,
            activities: [],
            comments: undefined,
        };

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshAddResponse(response: Client.AddGeoscienceAustraliaSurveyDataRelatedActivitiesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    public refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaSurveyDataRelatedActivityResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public clearStatusMessages(): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = undefined;
        vm.addRelatedActivities = {
            activityType: undefined,
            activities: [],
            comments: undefined,
        };
        return vm;
    }

    public onAddRelatedActivitiesChanged(addRelatedActivities: IAddSurveyDataRelatedActivities): IRootViewModel {
        const vm = this._clone();
        vm.addRelatedActivities = addRelatedActivities;
        vm.isDirty = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.referenceData = clone(this.referenceData);
        vm.addRelatedActivities = clone(this.addRelatedActivities);
        return vm;
    }
}
