import { GetGeoscienceAustraliaReportAttachmentsItemDto } from "../../api/Client";
import * as Validation from "../../common/validation/ValidationModel";

export default function validate(reportAttachment: GetGeoscienceAustraliaReportAttachmentsItemDto | undefined, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (!reportAttachment!.fileName?.trim()) {
        v.addError("FileName", "File Name is required.");
    }

    if (!reportAttachment!.documentLink?.trim()) {
        v.addError("DocumentLink", "Document Link is required.");
    }

    return v;
}
