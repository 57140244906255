import * as Client from "../../../api/Client";
import SimpleDataAuditDeltaNodeView from "./SimpleDataAuditDeltaNodeView";
import SimpleDataAuditSnapshotNodeView from "./SimpleDataAuditSnapshotNodeView";

interface IProps {
    change: Client.SecurePortalSimpleDataAuditChangeDto | undefined;
}

export default function SimpleDataAuditChangeView(props: IProps) {
    if (!props.change) return <></>;
    const { change } = props;

    return (
        <>
            {change.comments && <div>{change.comments}</div>}
            <SimpleDataAuditSnapshotNodeView snapshot={change.snapshot} />
            <SimpleDataAuditDeltaNodeView delta={change.delta} />
        </>
    );
}
