// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    well: Client.GetGeoscienceAustraliaWellsBoreholesWellDataAuditChangesResponseDto | undefined;

    refreshWellDataAuditChanges(response: Client.GetGeoscienceAustraliaWellsBoreholesWellDataAuditChangesResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    public well: Client.GetGeoscienceAustraliaWellsBoreholesWellDataAuditChangesResponseDto | undefined;

    public refreshWellDataAuditChanges(response: Client.GetGeoscienceAustraliaWellsBoreholesWellDataAuditChangesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.well = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.well = clone(this.well);
        return vm;
    }
}
