// dashboard
import * as DashboardReducers from "./home/dashboard/redux/reducers";
import * as DashboardModels from "./home/dashboard/models/models";
import DashboardSaga from "./home/dashboard/redux/saga";
// Wells/Boreholes - Well List
import * as WellListReducers from "./wellsBoreholes/well/list/redux/reducers";
import * as WellListModels from "./wellsBoreholes/well/list/models/models";
import WellListSaga from "./wellsBoreholes/well/list/redux/saga";
// Wells/Boreholes - Well Details
import * as WellDetailsReducers from "./wellsBoreholes/well/details/redux/reducers";
import * as WellDetailsModels from "./wellsBoreholes/well/details/models/models";
import WellDetailsSaga from "./wellsBoreholes/well/details/redux/saga";
// Wells - Well Report Attachments
import * as WellReportAttachmentsReducers from "./wellsBoreholes/well/attachments/redux/reducers";
import * as WellReportAttachmentsModels from "./wellsBoreholes/well/attachments/models/models";
import WellReportAttachmentsSaga from "./wellsBoreholes/well/attachments/redux/saga";
// Wells - Borehole Report Attachments
import * as BoreholeReportAttachmentsReducers from "./wellsBoreholes/borehole/attachments/redux/reducers";
import * as BoreholeReportAttachmentsModels from "./wellsBoreholes/borehole/attachments/models/models";
import BoreholeReportAttachmentsSaga from "./wellsBoreholes/borehole/attachments/redux/saga";
// Wells/Boreholes - Well Boreholes Details
import * as WellBoreholesReducers from "./wellsBoreholes/well/boreholes/redux/reducers";
import * as WellBoreholesModels from "./wellsBoreholes/well/boreholes/models/models";
import WellBoreholesSaga from "./wellsBoreholes/well/boreholes/redux/saga";
// Wells/Boreholes - Well Data Monitoring List
import * as WellDataMonitoringReducers from "./wellsBoreholes/well/dataMonitoring/redux/reducers";
import * as WellDataMonitoringModels from "./wellsBoreholes/well/dataMonitoring/models/models";
import WellDataMonitoringSaga from "./wellsBoreholes/well/dataMonitoring/redux/saga";
// Wells/Boreholes - Data Monitoring Item Details
import * as WellDataMonitoringItemDetailsReducers from "./wellsBoreholes/well/dataMonitoringItem/redux/reducers";
import * as WellDataMonitoringItemDetailsModels from "./wellsBoreholes/well/dataMonitoringItem/models/models";
import WellDataMonitoringItemDetailsSaga from "./wellsBoreholes/well/dataMonitoringItem/redux/saga";
// Wells/Boreholes - Well Reports Details
import * as WellReportsReducers from "./wellsBoreholes/well/reports/redux/reducers";
import * as WellReportsModels from "./wellsBoreholes/well/reports/models/models";
import WellReportsSaga from "./wellsBoreholes/well/reports/redux/saga";
// Wells/Boreholes - Well Auditing
import * as WellAuditingReducers from "./wellsBoreholes/well/auditing/redux/reducers";
import * as WellAuditingModels from "./wellsBoreholes/well/auditing/models/models";
import WellAuditingSaga from "./wellsBoreholes/well/auditing/redux/saga";
// Wells/Boreholes - Borehole List
import * as BoreholeListReducers from "./wellsBoreholes/borehole/list/redux/reducers";
import * as BoreholeListModels from "./wellsBoreholes/borehole/list/models/models";
import BoreholeListSaga from "./wellsBoreholes/borehole/list/redux/saga";
// Wells/Boreholes - Borehole Details
import * as BoreholeDetailsReducers from "./wellsBoreholes/borehole/details/redux/reducers";
import * as BoreholeDetailsModels from "./wellsBoreholes/borehole/details/models/models";
import BoreholeDetailsSaga from "./wellsBoreholes/borehole/details/redux/saga";
// Wells/Boreholes - Borehole Data Monitoring List
import * as BoreholeDataMonitoringReducers from "./wellsBoreholes/borehole/dataMonitoring/redux/reducers";
import * as BoreholeDataMonitoringModels from "./wellsBoreholes/borehole/dataMonitoring/models/models";
import BoreholeDataMonitoringSaga from "./wellsBoreholes/borehole/dataMonitoring/redux/saga";
// Wells/Boreholes - Borehole Reports Details
import * as BoreholeReportsReducers from "./wellsBoreholes/borehole/reports/redux/reducers";
import * as BoreholeReportsModels from "./wellsBoreholes/borehole/reports/models/models";
import BoreholeReportsSaga from "./wellsBoreholes/borehole/reports/redux/saga";
// Wells/Boreholes - Rig List
import * as RigListReducers from "./wellsBoreholes/rigList/redux/reducers";
import * as RigListModels from "./wellsBoreholes/rigList/models/models";
import RigListSaga from "./wellsBoreholes/rigList/redux/saga";
// Wells/Boreholes - Rig Details
import * as RigDetailsReducers from "./wellsBoreholes/rigDetails/redux/reducers";
import * as RigDetailsModels from "./wellsBoreholes/rigDetails/models/models";
import RigDetailsSaga from "./wellsBoreholes/rigDetails/redux/saga";
// Wells/Boreholes - Rig History List
import * as RigHistoryListReducers from "./wellsBoreholes/rigHistoryList/redux/reducers";
import * as RigHistoryListModels from "./wellsBoreholes/rigHistoryList/models/models";
import RigHistoryListSaga from "./wellsBoreholes/rigHistoryList/redux/saga";
// Wells/Boreholes - Report List
import * as ReportListReducers from "./reportsAndData/report/list/redux/reducers";
import * as ReportListModels from "./reportsAndData/report/list/models/models";
import ReportListSaga from "./reportsAndData/report/list/redux/saga";
// Wells/Boreholes - Report Create
import * as ReportCreateReducers from "./reportsAndData/report/create/redux/reducers";
import * as ReportCreateModels from "./reportsAndData/report/create/models/models";
import ReportCreateSaga from "./reportsAndData/report/create/redux/saga";
// Wells/Boreholes - Report Details
import * as ReportDetailsReducers from "./reportsAndData/report/details/redux/reducers";
import * as ReportDetailsModels from "./reportsAndData/report/details/models/models";
import ReportDetailsSaga from "./reportsAndData/report/details/redux/saga";
// Wells/Boreholes - Report Auditing
import * as ReportAuditingReducers from "./reportsAndData/report/auditing/redux/reducers";
import * as ReportAuditingModels from "./reportsAndData/report/auditing/models/models";
import ReportAuditingSaga from "./reportsAndData/report/auditing/redux/saga";
// Wells/Boreholes - Report FileAssets
import * as ReportFileAssetsReducers from "./reportsAndData/report/fileAssets/redux/reducers";
import * as ReportFileAssetsModels from "./reportsAndData/report/fileAssets/models/models";
import ReportFileAssetsSaga from "./reportsAndData/report/fileAssets/redux/saga";
// Wells/Boreholes - Report Related Activities
import * as ReportRelatedActivitiesReducers from "./reportsAndData/report/relatedActivities/redux/reducers";
import * as ReportRelatedActivitiesModels from "./reportsAndData/report/relatedActivities/models/models";
import ReportRelatedActivitiesSaga from "./reportsAndData/report/relatedActivities/redux/saga";
// Wells/Boreholes - Report Attachments
import * as ReportAttachmentsReducers from "./reportsAndData/report/attachments/redux/reducers";
import * as ReportAttachmentsModels from "./reportsAndData/report/attachments/models/models";
import ReportAttachmentsSaga from "./reportsAndData/report/attachments/redux/saga";
// Wells/Boreholes - Report List
import * as AttachmentListReducers from "./reportsAndData/attachments/list/redux/reducers";
import * as AttachmentListModels from "./reportsAndData/attachments/list/models/models";
import AttachmentListSaga from "./reportsAndData/attachments/list/redux/saga";
// Wells/Boreholes - Report List Bulk Delete
import * as AttachmentListBulkDeleteReducers from "./reportsAndData/attachments/list/controls/bulkDelete/redux/reducers";
import * as AttachmentListBulkDeleteModels from "./reportsAndData/attachments/list/controls/bulkDelete/models/models";
import AttachmentListBulkDeleteSaga from "./reportsAndData/attachments/list/controls/bulkDelete/redux/saga";
// Reports And Data - SurveyData List
import * as SurveyDataListReducers from "./reportsAndData/surveyData/list/redux/reducers";
import * as SurveyDataListModels from "./reportsAndData/surveyData/list/models/models";
import SurveyDataListSaga from "./reportsAndData/surveyData/list/redux/saga";
// Reports And Data - SurveyData Create
import * as SurveyDataCreateReducers from "./reportsAndData/surveyData/create/redux/reducers";
import * as SurveyDataCreateModels from "./reportsAndData/surveyData/create/models/models";
import SurveyDataCreateSaga from "./reportsAndData/surveyData/create/redux/saga";
// Reports And Data - Survey Data Details
import * as SurveyDataDetailsReducers from "./reportsAndData/surveyData/details/redux/reducers";
import * as SurveyDataDetailsModels from "./reportsAndData/surveyData/details/models/models";
import SurveyDataDetailsSaga from "./reportsAndData/surveyData/details/redux/saga";
// Reports And Data - Survey Data File Assets
import * as SurveyDataFileAssetsReducers from "./reportsAndData/surveyData/fileAssets/redux/reducers";
import * as SurveyDataFileAssetsModels from "./reportsAndData/surveyData/fileAssets/models/models";
import SurveyDataFileAssetsSaga from "./reportsAndData/surveyData/fileAssets/redux/saga";
// Reports And Data - Survey Data Related Activities
import * as SurveyDataRelatedActivitiesReducers from "./reportsAndData/surveyData/relatedActivities/redux/reducers";
import * as SurveyDataRelatedActivitiesModels from "./reportsAndData/surveyData/relatedActivities/models/models";
import SurveyDataRelatedActivitiesSaga from "./reportsAndData/surveyData/relatedActivities/redux/saga";
// Reports And Data - Survey Data Attachment List
import * as SurveyDataAttachmentsReducers from "./reportsAndData/surveyData/attachments/redux/reducers";
import * as SurveyDataAttachmentsModels from "./reportsAndData/surveyData/attachments/models/models";
import SurveyDataAttachmentsSaga from "./reportsAndData/surveyData/attachments/redux/saga";
// Reports And Data - Survey Data Lines List
import * as SurveyDataLinesReducers from "./reportsAndData/surveyData/lines/redux/reducers";
import * as SurveyDataLinesModels from "./reportsAndData/surveyData/lines/models/models";
import SurveyDataLinesSaga from "./reportsAndData/surveyData/lines/redux/saga";
// Reports And Data - Survey Data Auditing
import * as SurveyDataAuditingReducers from "./reportsAndData/surveyData/auditing/redux/reducers";
import * as SurveyDataAuditingModels from "./reportsAndData/surveyData/auditing/models/models";
import SurveyDataAuditingSaga from "./reportsAndData/surveyData/auditing/redux/saga";
// Surveys/Acquisitions - Survey List
import * as SurveyListReducers from "./surveysAcquisitions/survey/list/redux/reducers";
import * as SurveyListModels from "./surveysAcquisitions/survey/list/models/models";
import SurveyListSaga from "./surveysAcquisitions/survey/list/redux/saga";
// Surveys/Acquisitions - Survey Details
import * as SurveyDetailsReducers from "./surveysAcquisitions/survey/details/redux/reducers";
import * as SurveyDetailsModels from "./surveysAcquisitions/survey/details/models/models";
import SurveyDetailsSaga from "./surveysAcquisitions/survey/details/redux/saga";
// Surveys/Acquisitions - Survey Acquisitions
import * as SurveyAcquisitionsReducers from "./surveysAcquisitions/survey/acquisitions/redux/reducers";
import * as SurveyAcquisitionsModels from "./surveysAcquisitions/survey/acquisitions/models/models";
import SurveyAcquisitionsSaga from "./surveysAcquisitions/survey/acquisitions/redux/saga";
// Surveys/Acquisitions - Survey Titles
import * as SurveyTitlesReducers from "./surveysAcquisitions/survey/titles/redux/reducers";
import * as SurveyTitlesModels from "./surveysAcquisitions/survey/titles/models/models";
import SurveyTitlesSaga from "./surveysAcquisitions/survey/titles/redux/saga";
// Surveys/Acquisitions - Survey Data Monitoring
import * as SurveyDataMonitoringReducers from "./surveysAcquisitions/survey/dataMonitoring/redux/reducers";
import * as SurveyDataMonitoringModels from "./surveysAcquisitions/survey/dataMonitoring/models/models";
import SurveyDataMonitoringSaga from "./surveysAcquisitions/survey/dataMonitoring/redux/saga";
// Surveys/Acquisitions - Survey Parameters
import * as SurveyParameterReducers from "./surveysAcquisitions/survey/parameters/redux/reducers";
import * as SurveyParameterModels from "./surveysAcquisitions/survey/parameters/models/models";
import SurveyParameterSaga from "./surveysAcquisitions/survey/parameters/redux/saga";
// Surveys/Acquisitions - Data Monitoring Item Details
import * as SurveyDataMonitoringItemDetailsReducers from "./surveysAcquisitions/survey/dataMonitoringItem/redux/reducers";
import * as SurveyDataMonitoringItemDetailsModels from "./surveysAcquisitions/survey/dataMonitoringItem/models/models";
import SurveyDataMonitoringItemDetailsSaga from "./surveysAcquisitions/survey/dataMonitoringItem/redux/saga";
// Surveys/Acquisitions - Survey Reports Details
import * as SurveyReportsReducers from "./surveysAcquisitions/survey/reports/redux/reducers";
import * as SurveyReportsModels from "./surveysAcquisitions/survey/reports/models/models";
import SurveyReportsSaga from "./surveysAcquisitions/survey/reports/redux/saga";
// Surveys/Acquisitions - Survey Report Attachments
import * as SurveyReportAttachmentsReducers from "./surveysAcquisitions/survey/attachments/redux/reducers";
import * as SurveyReportAttachmentsModels from "./surveysAcquisitions/survey/attachments/models/models";
import SurveyReportAttachmentsSaga from "./surveysAcquisitions/survey/attachments/redux/saga";
// Surveys/Acquisitions - Survey Survey Data Details
import * as SurveySurveyDataReducers from "./surveysAcquisitions/survey/surveyData/redux/reducers";
import * as SurveySurveyDataModels from "./surveysAcquisitions/survey/surveyData/models/models";
import SurveySurveyDataSaga from "./surveysAcquisitions/survey/surveyData/redux/saga";
// Surveys/Acquisitions - Survey Auditing
import * as SurveyAuditingReducers from "./surveysAcquisitions/survey/auditing/redux/reducers";
import * as SurveyAuditingModels from "./surveysAcquisitions/survey/auditing/models/models";
import SurveyAuditingSaga from "./surveysAcquisitions/survey/auditing/redux/saga";
// Surveys/Acquisitions - Acquisition List
import * as AcquisitionListReducers from "./surveysAcquisitions/acquisition/list/redux/reducers";
import * as AcquisitionListModels from "./surveysAcquisitions/acquisition/list/models/models";
import AcquisitionListSaga from "./surveysAcquisitions/acquisition/list/redux/saga";
// Surveys/Acquisitions - Acquisition Details
import * as AcquisitionDetailsReducers from "./surveysAcquisitions/acquisition/details/redux/reducers";
import * as AcquisitionDetailsModels from "./surveysAcquisitions/acquisition/details/models/models";
import AcquisitionDetailsSaga from "./surveysAcquisitions/acquisition/details/redux/saga";
// Surveys/Acquisitions - Acquisition Data Monitoring
import * as AcquisitionDataMonitoringReducers from "./surveysAcquisitions/acquisition/dataMonitoring/redux/reducers";
import * as AcquisitionDataMonitoringModels from "./surveysAcquisitions/acquisition/dataMonitoring/models/models";
import AcquisitionDataMonitoringSaga from "./surveysAcquisitions/acquisition/dataMonitoring/redux/saga";
// Surveys/Acquisitions - Acquisition Parameters
import * as AcquisitionParameterReducers from "./surveysAcquisitions/acquisition/parameters/redux/reducers";
import * as AcquisitionParameterModels from "./surveysAcquisitions/acquisition/parameters/models/models";
import AcquisitionParameterSaga from "./surveysAcquisitions/acquisition/parameters/redux/saga";
// Surveys/Acquisitions - Acquisition Reports Details
import * as AcquisitionReportsReducers from "./surveysAcquisitions/acquisition/reports/redux/reducers";
import * as AcquisitionReportsModels from "./surveysAcquisitions/acquisition/reports/models/models";
import AcquisitionReportsSaga from "./surveysAcquisitions/acquisition/reports/redux/saga";
// Surveys/Acquisitions - Acquisition Report Attachments
import * as AcquisitionReportAttachmentsReducers from "./surveysAcquisitions/acquisition/attachments/redux/reducers";
import * as AcquisitionReportAttachmentsModels from "./surveysAcquisitions/acquisition/attachments/models/models";
import AcquisitionReportAttachmentsSaga from "./surveysAcquisitions/acquisition/attachments/redux/saga";
// Surveys/Acquisitions - Acquisition Survey Data Details
import * as AcquisitionSurveyDataReducers from "./surveysAcquisitions/acquisition/surveyData/redux/reducers";
import * as AcquisitionSurveyDataModels from "./surveysAcquisitions/acquisition/surveyData/models/models";
import AcquisitionSurveyDataSaga from "./surveysAcquisitions/acquisition/surveyData/redux/saga";
// Reprocessings/Projects - Reprocessing List
import * as ReprocessingListReducers from "./reprocessingsProjects/reprocessing/list/redux/reducers";
import * as ReprocessingListModels from "./reprocessingsProjects/reprocessing/list/models/models";
import ReprocessingListSaga from "./reprocessingsProjects/reprocessing/list/redux/saga";
// Reprocessings/Projects - Reprocessing Details
import * as ReprocessingDetailsReducers from "./reprocessingsProjects/reprocessing/details/redux/reducers";
import * as ReprocessingDetailsModels from "./reprocessingsProjects/reprocessing/details/models/models";
import ReprocessingDetailsSaga from "./reprocessingsProjects/reprocessing/details/redux/saga";
// Reprocessings/Projects - Reprocessing Projects
import * as ReprocessingProjectsReducers from "./reprocessingsProjects/reprocessing/projects/redux/reducers";
import * as ReprocessingProjectsModels from "./reprocessingsProjects/reprocessing/projects/models/models";
import ReprocessingProjectsSaga from "./reprocessingsProjects/reprocessing/projects/redux/saga";
// Reprocessings/Projects - Reprocessing Titles
import * as ReprocessingTitlesReducers from "./reprocessingsProjects/reprocessing/titles/redux/reducers";
import * as ReprocessingTitlesModels from "./reprocessingsProjects/reprocessing/titles/models/models";
import ReprocessingTitlesSaga from "./reprocessingsProjects/reprocessing/titles/redux/saga";
// Reprocessings/Projects - Reprocessing Acquisitions
import * as ReprocessingAcquisitionsReducers from "./reprocessingsProjects/reprocessing/acquisitions/redux/reducers";
import * as ReprocessingAcquisitionsModels from "./reprocessingsProjects/reprocessing/acquisitions/models/models";
import ReprocessingAcquisitionsSaga from "./reprocessingsProjects/reprocessing/acquisitions/redux/saga";
// Reprocessings/Projects - Reprocessing Data Monitoring
import * as ReprocessingDataMonitoringReducers from "./reprocessingsProjects/reprocessing/dataMonitoring/redux/reducers";
import * as ReprocessingDataMonitoringModels from "./reprocessingsProjects/reprocessing/dataMonitoring/models/models";
import ReprocessingDataMonitoringSaga from "./reprocessingsProjects/reprocessing/dataMonitoring/redux/saga";
// Reprocessings/Projects - Data Monitoring Item Details
import * as ReprocessingDataMonitoringItemDetailsReducers from "./reprocessingsProjects/reprocessing/dataMonitoringItem/redux/reducers";
import * as ReprocessingDataMonitoringItemDetailsModels from "./reprocessingsProjects/reprocessing/dataMonitoringItem/models/models";
import ReprocessingDataMonitoringItemDetailsSaga from "./reprocessingsProjects/reprocessing/dataMonitoringItem/redux/saga";
// Reprocessings/Projects - Reprocessing Reports Details
import * as ReprocessingReportsReducers from "./reprocessingsProjects/reprocessing/reports/redux/reducers";
import * as ReprocessingReportsModels from "./reprocessingsProjects/reprocessing/reports/models/models";
import ReprocessingReportsSaga from "./reprocessingsProjects/reprocessing/reports/redux/saga";
// Reprocessings - Reprocessing Report Attachments
import * as ReprocessingReportAttachmentsReducers from "./reprocessingsProjects/reprocessing/attachments/redux/reducers";
import * as ReprocessingReportAttachmentsModels from "./reprocessingsProjects/reprocessing/attachments/models/models";
import ReprocessingReportAttachmentsSaga from "./reprocessingsProjects/reprocessing/attachments/redux/saga";
// Reprocessings/Projects - Reprocessing Auditing
import * as ReprocessingAuditingReducers from "./reprocessingsProjects/reprocessing/auditing/redux/reducers";
import * as ReprocessingAuditingModels from "./reprocessingsProjects/reprocessing/auditing/models/models";
import ReprocessingAuditingSaga from "./reprocessingsProjects/reprocessing/auditing/redux/saga";
// Reprocessings/Projects - Reprocessing Project List
import * as ReprocessingProjectListReducers from "./reprocessingsProjects/reprocessingProject/list/redux/reducers";
import * as ReprocessingProjectListModels from "./reprocessingsProjects/reprocessingProject/list/models/models";
import ReprocessingProjectListSaga from "./reprocessingsProjects/reprocessingProject/list/redux/saga";
// Reprocessings/Projects - Reprocessing Project Details
import * as ReprocessingProjectDetailsReducers from "./reprocessingsProjects/reprocessingProject/details/redux/reducers";
import * as ReprocessingProjectDetailsModels from "./reprocessingsProjects/reprocessingProject/details/models/models";
import ReprocessingProjectDetailsSaga from "./reprocessingsProjects/reprocessingProject/details/redux/saga";
// Reprocessings/Projects - Reprocessing Project Titles
import * as ReprocessingProjectTitlesReducers from "./reprocessingsProjects/reprocessingProject/titles/redux/reducers";
import * as ReprocessingProjectTitlesModels from "./reprocessingsProjects/reprocessingProject/titles/models/models";
import ReprocessingProjectTitlesSaga from "./reprocessingsProjects/reprocessingProject/titles/redux/saga";
// Reprocessings/Projects - Reprocessing Project Acquisitions
import * as ReprocessingProjectAcquisitionsReducers from "./reprocessingsProjects/reprocessingProject/acquisitions/redux/reducers";
import * as ReprocessingProjectAcquisitionsModels from "./reprocessingsProjects/reprocessingProject/acquisitions/models/models";
import ReprocessingProjectAcquisitionsSaga from "./reprocessingsProjects/reprocessingProject/acquisitions/redux/saga";
// Reprocessings/Projects - Reprocessing Project Data Monitoring
import * as ReprocessingProjectDataMonitoringReducers from "./reprocessingsProjects/reprocessingProject/dataMonitoring/redux/reducers";
import * as ReprocessingProjectDataMonitoringModels from "./reprocessingsProjects/reprocessingProject/dataMonitoring/models/models";
import ReprocessingProjectDataMonitoringSaga from "./reprocessingsProjects/reprocessingProject/dataMonitoring/redux/saga";
// Reprocessings/Projects - Project Reports Details
import * as ProjectReportsReducers from "./reprocessingsProjects/reprocessingProject/reports/redux/reducers";
import * as ProjectReportsModels from "./reprocessingsProjects/reprocessingProject/reports/models/models";
import ProjectReportsSaga from "./reprocessingsProjects/reprocessingProject/reports/redux/saga";
// Reprocessings - Borehole Report Attachments
import * as ReprocessingProjectReportAttachmentsReducers from "./reprocessingsProjects/reprocessingProject/attachments/redux/reducers";
import * as ReprocessingProjectReportAttachmentsModels from "./reprocessingsProjects/reprocessingProject/attachments/models/models";
import ReprocessingProjectReportAttachmentsSaga from "./reprocessingsProjects/reprocessingProject/attachments/redux/saga";
// Reprocessings/Projects - Reprocessing Survey Data Details
import * as ReprocessingSurveyDataReducers from "./reprocessingsProjects/reprocessing/surveyData/redux/reducers";
import * as ReprocessingSurveyDataModels from "./reprocessingsProjects/reprocessing/surveyData/models/models";
import ReprocessingSurveyDataSaga from "./reprocessingsProjects/reprocessing/surveyData/redux/saga";
// Reprocessings/Projects - Reprocessing Project Survey Data Details
import * as ReprocessingProjectSurveyDataReducers from "./reprocessingsProjects/reprocessingProject/surveyData/redux/reducers";
import * as ReprocessingProjectSurveyDataModels from "./reprocessingsProjects/reprocessingProject/surveyData/models/models";
import ReprocessingProjectSurveyDataSaga from "./reprocessingsProjects/reprocessingProject/surveyData/redux/saga";
// DataSubmissions - Data Submission Details
import * as DataSubmissionDetailsReducers from "./dataSubmissions/dataSubmission/details/redux/reducers";
import * as DataSubmissionDetailsModels from "./dataSubmissions/dataSubmission/details/models/models";
import DataSubmissionDetailsSaga from "./dataSubmissions/dataSubmission/details/redux/saga";
// DataSubmissions - Data Submission Data Monitoring List
import * as DataSubmissionDataMonitoringReducers from "./dataSubmissions/dataSubmission/dataMonitoring/redux/reducers";
import * as DataSubmissionDataMonitoringModels from "./dataSubmissions/dataSubmission/dataMonitoring/models/models";
import DataSubmissionDataMonitoringSaga from "./dataSubmissions/dataSubmission/dataMonitoring/redux/saga";
// Wells/Boreholes - Data Submission Resubmissions List
import * as DataSubmissionResubmissionsReducers from "./dataSubmissions/dataSubmission/resubmissions/redux/reducers";
import * as DataSubmissionResubmissionsModels from "./dataSubmissions/dataSubmission/resubmissions/models/models";
import DataSubmissionResubmissionsSaga from "./dataSubmissions/dataSubmission/resubmissions/redux/saga";
// DataSubmissions - Data Submission Auditing
import * as DataSubmissionAuditingReducers from "./dataSubmissions/dataSubmission/auditing/redux/reducers";
import * as DataSubmissionAuditingModels from "./dataSubmissions/dataSubmission/auditing/models/models";
import DataSubmissionAuditingSaga from "./dataSubmissions/dataSubmission/auditing/redux/saga";
// DataSubmissions - Data Submission Resubmission Details
import * as DataSubmissionResubmissionDetailsReducers from "./dataSubmissions/dataSubmission/resubmissionDetails/redux/reducers";
import * as DataSubmissionResubmissionDetailsModels from "./dataSubmissions/dataSubmission/resubmissionDetails/models/models";
import DataSubmissionResubmissionDetailsSaga from "./dataSubmissions/dataSubmission/resubmissionDetails/redux/saga";
// DataSubmissions - Authorisation List
import * as AuthorisationListReducers from "./dataSubmissions/authorisation/list/redux/reducers";
import * as AuthorisationListModels from "./dataSubmissions/authorisation/list/models/models";
import AuthorisationListSaga from "./dataSubmissions/authorisation/list/redux/saga";
// DataSubmissions - Authorisation Details
import * as AuthorisationDetailsReducers from "./dataSubmissions/authorisation/details/redux/reducers";
import * as AuthorisationDetailsModels from "./dataSubmissions/authorisation/details/models/models";
import AuthorisationDetailsSaga from "./dataSubmissions/authorisation/details/redux/saga";
// DataSubmissions - Authorisation Auditing
import * as AuthorisationAuditingReducers from "./dataSubmissions/authorisation/auditing/redux/reducers";
import * as AuthorisationAuditingModels from "./dataSubmissions/authorisation/auditing/models/models";
import AuthorisationAuditingSaga from "./dataSubmissions/authorisation/auditing/redux/saga";
// Feedback - Feedback List
import * as FeedbackListReducers from "./feedback/feedbackList/redux/reducers";
import * as FeedbackListModels from "./feedback/feedbackList/models/models";
import FeedbackListSaga from "./feedback/feedbackList/redux/saga";
// Feedback - Feedback Details
import * as FeedbackDetailsReducers from "./feedback/feedbackDetails/redux/reducers";
import * as FeedbackDetailsModels from "./feedback/feedbackDetails/models/models";
import FeedbackDetailsSaga from "./feedback/feedbackDetails/redux/saga";
// registration
import * as RegistrationReducers from "./registration/redux/reducers";
import * as RegistrationModels from "./registration/models/models";
import RegistrationSaga from "./registration/redux/saga";
// User Management - GeoscienceAustralia
import * as GeoscienceAustraliaReducers from "./userManagement/geoscienceAustraliaDetails/redux/reducers";
import * as GeoscienceAustraliaModels from "./userManagement/geoscienceAustraliaDetails/models/rootViewModel";
import GeoscienceAustraliaSaga from "./userManagement/geoscienceAustraliaDetails/redux/saga";
// User Management - users list
import * as UserListReducers from "./userManagement/userList/redux/reducers";
import * as UserListModels from "./userManagement/userList/models/models";
import UserListSaga from "./userManagement/userList/redux/saga";
// User Management - users details
import * as UserDetailsReducers from "./userManagement/userDetails/redux/reducers";
import * as UserDetailsModels from "./userManagement/userDetails/models/models";
import UserDetailsSaga from "./userManagement/userDetails/redux/saga";
// request list
import * as RequestListReducers from "./userManagement/requestList/redux/reducers";
import * as RequestListModels from "./userManagement/requestList/models/rootViewModel";
import RequestListSaga from "./userManagement/requestList/redux/saga";
// request details
import * as RequestDetailsReducers from "./userManagement/requestDetails/redux/reducers";
import * as RequestDetailsModels from "./userManagement/requestDetails/models/rootViewModel";
import RequestDetailsSaga from "./userManagement/requestDetails/redux/saga";
// my account - myDetails
import * as MyDetailsReducers from "./account/myDetails/redux/reducers";
import * as MyDetailsModels from "./account/myDetails/models/models";
import MyDetailsSaga from "./account/myDetails/redux/saga";
// my account - request access
import * as RequestAccessReducers from "./account/requestAccess/redux/reducers";
import * as RequestAccessModels from "./account/requestAccess/models/models";
import RequestAccessSaga from "./account/requestAccess/redux/saga";

// state
export interface IGeoscienceAustraliaReduxState {
    GeoscienceAustralia_Home_Dashboard: DashboardModels.IRootViewModel;
    GeoscienceAustralia_Home_Registration: RegistrationModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_WellList: WellListModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_WellDetails: WellDetailsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_WellReportAttachments: WellReportAttachmentsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_BoreholeReportAttachments: BoreholeReportAttachmentsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_WellBoreholes: WellBoreholesModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_WellDataMonitoring: WellDataMonitoringModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_WellDataMonitoringItemDetails: WellDataMonitoringItemDetailsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_WellReports: WellReportsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_WellAuditing: WellAuditingModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_BoreholeList: BoreholeListModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_BoreholeDetails: BoreholeDetailsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_BoreholeDataMonitoring: BoreholeDataMonitoringModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_BoreholeReports: BoreholeReportsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_AuthorisationList: AuthorisationListModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_AuthorisationDetails: AuthorisationDetailsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_AuthorisationAuditing: AuthorisationAuditingModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_RigList: RigListModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_RigDetails: RigDetailsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_RigHistoryList: RigHistoryListModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_ReportList: ReportListModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_ReportCreate: ReportCreateModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_ReportDetails: ReportDetailsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_ReportAuditing: ReportAuditingModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_ReportFileAssets: ReportFileAssetsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_ReportRelatedActivities: ReportRelatedActivitiesModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_ReportAttachmentList: ReportAttachmentsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_DataSubmissionDetails: DataSubmissionDetailsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_DataSubmissionDataMonitoring: DataSubmissionDataMonitoringModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_DataSubmissionResubmissions: DataSubmissionResubmissionsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_DataSubmissionAuditing: DataSubmissionAuditingModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_DataSubmissionResubmissionDetails: DataSubmissionResubmissionDetailsModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_AttachmentList: AttachmentListModels.IRootViewModel;
    GeoscienceAustralia_WellsBoreholes_AttachmentList_BulkDelete: AttachmentListBulkDeleteModels.IRootViewModel;
    GeoscienceAustralia_ReportsAndData_SurveyDataList: SurveyDataListModels.IRootViewModel;
    GeoscienceAustralia_ReportsAndData_SurveyDataCreate: SurveyDataCreateModels.IRootViewModel;
    GeoscienceAustralia_ReportsAndData_SurveyDataDetails: SurveyDataDetailsModels.IRootViewModel;
    GeoscienceAustralia_ReportsAndData_SurveyDataFileAssets: SurveyDataFileAssetsModels.IRootViewModel;
    GeoscienceAustralia_ReportsAndData_SurveyDataRelatedActivities: SurveyDataRelatedActivitiesModels.IRootViewModel;
    GeoscienceAustralia_ReportsAndData_SurveyDataAttachments: SurveyDataAttachmentsModels.IRootViewModel;
    GeoscienceAustralia_ReportsAndData_SurveyDataLines: SurveyDataLinesModels.IRootViewModel;
    GeoscienceAustralia_ReportsAndData_SurveyDataAuditing: SurveyDataAuditingModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyList: SurveyListModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyDetails: SurveyDetailsModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyAcquisitions: SurveyAcquisitionsModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyTitles: SurveyTitlesModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyDataMonitoring: SurveyDataMonitoringModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyParameter: SurveyParameterModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyDataMonitoringItemDetails: SurveyDataMonitoringItemDetailsModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyReports: SurveyReportsModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyReportAttachments: SurveyReportAttachmentsModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveySurveyData: SurveySurveyDataModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_SurveyAuditing: SurveyAuditingModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_AcquisitionList: AcquisitionListModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_AcquisitionDetails: AcquisitionDetailsModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_AcquisitionDataMonitoring: AcquisitionDataMonitoringModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_AcquisitionParameter: AcquisitionParameterModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_AcquisitionReports: AcquisitionReportsModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_AcquisitionReportAttachments: AcquisitionReportAttachmentsModels.IRootViewModel;
    GeoscienceAustralia_SurveysAcquisitions_AcquisitionSurveyData: AcquisitionSurveyDataModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingList: ReprocessingListModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingDetails: ReprocessingDetailsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjects: ReprocessingProjectsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingTitles: ReprocessingTitlesModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingAcquisitions: ReprocessingAcquisitionsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingDataMonitoring: ReprocessingDataMonitoringModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingDataMonitoringItemDetails: ReprocessingDataMonitoringItemDetailsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingReports: ReprocessingReportsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingReportAttachments: ReprocessingReportAttachmentsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingAuditing: ReprocessingAuditingModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectList: ReprocessingProjectListModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectDetails: ReprocessingProjectDetailsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectTitles: ReprocessingProjectTitlesModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectAcquisitions: ReprocessingProjectAcquisitionsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectDataMonitoring: ReprocessingProjectDataMonitoringModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectReports: ProjectReportsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectReportAttachments: ReprocessingProjectReportAttachmentsModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingSurveyData: ReprocessingSurveyDataModels.IRootViewModel;
    GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectSurveyData: ReprocessingProjectSurveyDataModels.IRootViewModel;
    GeoscienceAustralia_Feedback_FeedbackList: FeedbackListModels.IRootViewModel;
    GeoscienceAustralia_Feedback_FeedbackDetails: FeedbackDetailsModels.IRootViewModel;
    GeoscienceAustralia_UserManagement_GeoscienceAustraliaDetails: GeoscienceAustraliaModels.IRootViewModel;
    GeoscienceAustralia_UserManagement_UserList: UserListModels.IRootViewModel;
    GeoscienceAustralia_UserManagement_UserDetails: UserDetailsModels.IRootViewModel;
    GeoscienceAustralia_UserManagement_RequestList: RequestListModels.IRootViewModel;
    GeoscienceAustralia_UserManagement_RequestDetails: RequestDetailsModels.IRootViewModel;
    GeoscienceAustralia_Account_RequestAccess: RequestAccessModels.IRootViewModel;
    GeoscienceAustralia_Account_MyDetails: MyDetailsModels.IRootViewModel;
}

// reducers
export function buildReducers(): any {
    return {
        GeoscienceAustralia_Home_Dashboard: DashboardReducers.reducer,
        GeoscienceAustralia_Home_Registration: RegistrationReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_WellList: WellListReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_WellDetails: WellDetailsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_WellReportAttachments: WellReportAttachmentsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_BoreholeReportAttachments: BoreholeReportAttachmentsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_WellBoreholes: WellBoreholesReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_WellDataMonitoring: WellDataMonitoringReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_WellDataMonitoringItemDetails: WellDataMonitoringItemDetailsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_WellReports: WellReportsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_WellAuditing: WellAuditingReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_BoreholeList: BoreholeListReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_BoreholeDetails: BoreholeDetailsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_BoreholeDataMonitoring: BoreholeDataMonitoringReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_BoreholeReports: BoreholeReportsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_AuthorisationList: AuthorisationListReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_AuthorisationDetails: AuthorisationDetailsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_AuthorisationAuditing: AuthorisationAuditingReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_RigList: RigListReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_RigDetails: RigDetailsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_RigHistoryList: RigHistoryListReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_ReportList: ReportListReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_ReportCreate: ReportCreateReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_ReportDetails: ReportDetailsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_ReportAuditing: ReportAuditingReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_ReportFileAssets: ReportFileAssetsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_ReportRelatedActivities: ReportRelatedActivitiesReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_ReportAttachmentList: ReportAttachmentsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_DataSubmissionDetails: DataSubmissionDetailsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_DataSubmissionDataMonitoring: DataSubmissionDataMonitoringReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_DataSubmissionResubmissions: DataSubmissionResubmissionsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_DataSubmissionAuditing: DataSubmissionAuditingReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_DataSubmissionResubmissionDetails: DataSubmissionResubmissionDetailsReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_AttachmentList: AttachmentListReducers.reducer,
        GeoscienceAustralia_WellsBoreholes_AttachmentList_BulkDelete: AttachmentListBulkDeleteReducers.reducer,
        GeoscienceAustralia_ReportsAndData_SurveyDataList: SurveyDataListReducers.reducer,
        GeoscienceAustralia_ReportsAndData_SurveyDataCreate: SurveyDataCreateReducers.reducer,
        GeoscienceAustralia_ReportsAndData_SurveyDataDetails: SurveyDataDetailsReducers.reducer,
        GeoscienceAustralia_ReportsAndData_SurveyDataFileAssets: SurveyDataFileAssetsReducers.reducer,
        GeoscienceAustralia_ReportsAndData_SurveyDataRelatedActivities: SurveyDataRelatedActivitiesReducers.reducer,
        GeoscienceAustralia_ReportsAndData_SurveyDataAttachments: SurveyDataAttachmentsReducers.reducer,
        GeoscienceAustralia_ReportsAndData_SurveyDataLines: SurveyDataLinesReducers.reducer,
        GeoscienceAustralia_ReportsAndData_SurveyDataAuditing: SurveyDataAuditingReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyList: SurveyListReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyDetails: SurveyDetailsReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyAcquisitions: SurveyAcquisitionsReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyTitles: SurveyTitlesReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyDataMonitoring: SurveyDataMonitoringReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyParameter: SurveyParameterReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyDataMonitoringItemDetails: SurveyDataMonitoringItemDetailsReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyReports: SurveyReportsReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyReportAttachments: SurveyReportAttachmentsReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveySurveyData: SurveySurveyDataReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_SurveyAuditing: SurveyAuditingReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_AcquisitionList: AcquisitionListReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_AcquisitionDetails: AcquisitionDetailsReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_AcquisitionDataMonitoring: AcquisitionDataMonitoringReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_AcquisitionParameter: AcquisitionParameterReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_AcquisitionReports: AcquisitionReportsReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_AcquisitionReportAttachments: AcquisitionReportAttachmentsReducers.reducer,
        GeoscienceAustralia_SurveysAcquisitions_AcquisitionSurveyData: AcquisitionSurveyDataReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingList: ReprocessingListReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingDetails: ReprocessingDetailsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjects: ReprocessingProjectsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingTitles: ReprocessingTitlesReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingAcquisitions: ReprocessingAcquisitionsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingDataMonitoring: ReprocessingDataMonitoringReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingDataMonitoringItemDetails: ReprocessingDataMonitoringItemDetailsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingReports: ReprocessingReportsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingReportAttachments: ReprocessingReportAttachmentsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingAuditing: ReprocessingAuditingReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectList: ReprocessingProjectListReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectDetails: ReprocessingProjectDetailsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectTitles: ReprocessingProjectTitlesReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectAcquisitions: ReprocessingProjectAcquisitionsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectDataMonitoring: ReprocessingProjectDataMonitoringReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectReports: ProjectReportsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectReportAttachments: ReprocessingProjectReportAttachmentsReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingSurveyData: ReprocessingSurveyDataReducers.reducer,
        GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectSurveyData: ReprocessingProjectSurveyDataReducers.reducer,
        GeoscienceAustralia_Feedback_FeedbackList: FeedbackListReducers.reducer,
        GeoscienceAustralia_Feedback_FeedbackDetails: FeedbackDetailsReducers.reducer,
        GeoscienceAustralia_UserManagement_GeoscienceAustraliaDetails: GeoscienceAustraliaReducers.reducer,
        GeoscienceAustralia_UserManagement_UserList: UserListReducers.reducer,
        GeoscienceAustralia_UserManagement_UserDetails: UserDetailsReducers.reducer,
        GeoscienceAustralia_UserManagement_RequestList: RequestListReducers.reducer,
        GeoscienceAustralia_UserManagement_RequestDetails: RequestDetailsReducers.reducer,
        GeoscienceAustralia_Account_RequestAccess: RequestAccessReducers.reducer,
        GeoscienceAustralia_Account_MyDetails: MyDetailsReducers.reducer,
    };
}

// sagas
export function buildSagas(): Array<any> {
    const GeoscienceAustraliaSagas = [
        DashboardSaga,
        RegistrationSaga,
        WellListSaga,
        WellDetailsSaga,
        WellReportAttachmentsSaga,
        BoreholeReportAttachmentsSaga,
        WellBoreholesSaga,
        WellDataMonitoringSaga,
        WellDataMonitoringItemDetailsSaga,
        WellReportsSaga,
        WellAuditingSaga,
        BoreholeListSaga,
        BoreholeDetailsSaga,
        BoreholeDataMonitoringSaga,
        BoreholeReportsSaga,
        AuthorisationListSaga,
        AuthorisationDetailsSaga,
        AuthorisationAuditingSaga,
        RigListSaga,
        RigDetailsSaga,
        RigHistoryListSaga,
        ReportListSaga,
        ReportCreateSaga,
        ReportDetailsSaga,
        ReportAuditingSaga,
        ReportFileAssetsSaga,
        ReportRelatedActivitiesSaga,
        ReportAttachmentsSaga,
        SurveyDataListSaga,
        SurveyDataCreateSaga,
        SurveyDataDetailsSaga,
        SurveyDataFileAssetsSaga,
        SurveyDataRelatedActivitiesSaga,
        SurveyDataAttachmentsSaga,
        SurveyDataLinesSaga,
        SurveyDataAuditingSaga,
        DataSubmissionDetailsSaga,
        DataSubmissionDataMonitoringSaga,
        DataSubmissionResubmissionsSaga,
        DataSubmissionAuditingSaga,
        DataSubmissionResubmissionDetailsSaga,
        AttachmentListSaga,
        AttachmentListBulkDeleteSaga,
        SurveyListSaga,
        SurveyDetailsSaga,
        SurveyAcquisitionsSaga,
        SurveyTitlesSaga,
        SurveyDataMonitoringSaga,
        SurveyParameterSaga,
        SurveyDataMonitoringItemDetailsSaga,
        SurveyReportsSaga,
        SurveyReportAttachmentsSaga,
        SurveySurveyDataSaga,
        SurveyAuditingSaga,
        AcquisitionListSaga,
        AcquisitionDetailsSaga,
        AcquisitionDataMonitoringSaga,
        AcquisitionParameterSaga,
        AcquisitionReportsSaga,
        AcquisitionReportAttachmentsSaga,
        AcquisitionSurveyDataSaga,
        ReprocessingListSaga,
        ReprocessingDetailsSaga,
        ReprocessingProjectsSaga,
        ReprocessingTitlesSaga,
        ReprocessingAcquisitionsSaga,
        ReprocessingDataMonitoringSaga,
        ReprocessingDataMonitoringItemDetailsSaga,
        ReprocessingReportsSaga,
        ReprocessingReportAttachmentsSaga,
        ReprocessingAuditingSaga,
        ReprocessingProjectListSaga,
        ReprocessingProjectDetailsSaga,
        ReprocessingProjectTitlesSaga,
        ReprocessingProjectAcquisitionsSaga,
        ReprocessingProjectDataMonitoringSaga,
        ReprocessingProjectReportAttachmentsSaga,
        ReprocessingSurveyDataSaga,
        ReprocessingProjectSurveyDataSaga,
        ProjectReportsSaga,
        FeedbackListSaga,
        FeedbackDetailsSaga,
        GeoscienceAustraliaSaga,
        UserListSaga,
        UserDetailsSaga,
        RequestListSaga,
        RequestDetailsSaga,
        RequestAccessSaga,
        MyDetailsSaga,
    ];

    return GeoscienceAustraliaSagas;
}
