// framework
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { ISurveyDataItemViewModel, IRootViewModel, ViewStateEnum } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";
import LinksView, { LinkDetail } from "../../../../../common/links/LinksView";

export default function SurveyDataListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const [surveyDataItemsState, setSurveyDataItemsState] = useState(new Array<ISurveyDataItemViewModel>());

    const initialState: State = {
        sort: [{ field: "receivedDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);

    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.surveyData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        switch (vm.viewState) {
            case ViewStateEnum.View:
                setSurveyDataItemsState(vm.surveyData);
                setGridState(gridState);
                break;
        }
    }, [vm.viewState, vm.surveyData, gridState]);

    const rowRender = (trElement: any, props: any) => {
        const failedStyle = {
            backgroundColor: "#F8D7DA",
        };
        const trProps = {
            style: props.dataItem.updateFailed ? failedStyle : undefined,
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };

    return (
        <>
            <LinksView
                title="Reprocessing"
                canViewLink={vm.canViewReprocessings}
                linkTooltip="Click to view Reprocessing Survey Data."
                links={[new LinkDetail(vm.reprocessingName, GeoscienceAustraliaRouteHelper.reprocessingSurveyData(vm.reprocessingId!))]}
            />
            <Grid
                pageable
                sortable={true}
                resizable
                navigatable
                rowRender={rowRender}
                data={process(surveyDataItemsState, gridState)}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
                {...gridState}
            >
                <GridColumn
                    field="itemId"
                    title="Item ID"
                    width={140}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={GeoscienceAustraliaRouteHelper.surveyDataDetails(props.dataItem.id)} title="Click to view Survey Data Details.">
                                {props.dataItem.itemId}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn title="Title" field="title" width={500} sortable={false} columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="dataType" title="Data Type" width={240} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />
                <GridColumn field="tapeId" title="Tape ID" width={190} filter="text" columnMenu={createGridColumnFilterMenu} />

                <GridColumn field="receivedDate" title="Received Date" width={190} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />

                <GridColumn field="isConfidential" title="Confidentiality" width={190} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />

                <GridColumn
                    title="Confidentiality Remarks"
                    width={260}
                    sortable={false}
                    field="confidentialityRemarks"
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.confidentialityRemarks} maxSummaryLength={20} title="Confidentiality Remarks"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="conditionStatus" title="Condition Status" width={200} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />
                <GridColumn field="loanStatus" title="Loan Status" width={170} columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveyData)} />
                <GridColumn
                    title="Consignment"
                    width={250}
                    sortable={false}
                    field="consignment"
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.consignment} maxSummaryLength={20} title="Consignment"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
