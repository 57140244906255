// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";
// common
import { AdvancedSearchFilter, IAdvancedSearchFilter, IAdvancedSearchReferenceData } from "../../../../../common/advancedSearch/models";

export enum ViewStateEnum {
    Initialising,
    View,
    Searching,
}

export interface IImportAttachmentFile {
    fileName: string;
    fileContent: string;
}

export interface IAttachmentItemViewModel {
    id: number;
    attachmentType: Client.AdvancedSearchOperationAttachmentTypeDto;
    attachmentTypeId: number;
    attachmentTypeName: string;
    attachmentTypeVersionNumber: number;
    attachmentTypeItemId: string;
    documentLink: string;
    fileName: string;
    fileSize: number;
    numberOfMappings: number;
    bulkLoadNumber: string | undefined;
    uploadedBy: string | undefined;
    uploadedDate: Date | undefined;
    isSelected: boolean;
    dateLastModified: Date;
    relatedActivities: Client.GetGeoscienceAustraliaAttachmentListRelatedActivityDto[];
    relatedActivitiesString: string | undefined;
}

export interface IRootViewModel {
    attachments: IAttachmentItemViewModel[];
    isResultSetLimited: boolean;
    searchText: string;
    advancedSearchFilter?: IAdvancedSearchFilter | undefined;
    advancedSearchReferenceData: IAdvancedSearchReferenceData;
    attachmentTypeReferenceData: Client.AdvancedSearchOperationAttachmentTypeDto[];

    statusMessages?: Client.StatusMessagesDto;

    viewState: ViewStateEnum;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;
    canViewReports: boolean;
    canViewSurveyData: boolean;
    importAttachmentFile?: IImportAttachmentFile;

    refreshList(response: Client.GetGeoscienceAustraliaAttachmentListResponseDto): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaAttachmentListReferenceDataResponseDto): IRootViewModel;

    refreshStatusMessages(statusMessages?: Client.StatusMessagesDto | undefined): IRootViewModel;
    refreshImportAttachmentFile(importAttachmentFile?: IImportAttachmentFile): IRootViewModel;
    refreshSelectedAttachment(attachment: AttachmentItemViewModel): IRootViewModel;
    clearSelectedAttachments(): IRootViewModel;
    changeSearchFilter(searchText: string, advancedSearchFilter: IAdvancedSearchFilter): IRootViewModel;
    clearAllSearchFilters(): IRootViewModel;
}

export class AttachmentItemViewModel implements IAttachmentItemViewModel {
    constructor(attachment: Client.GetGeoscienceAustraliaAttachmentListItemDto, attachmentTypeReferenceData: Client.AdvancedSearchOperationAttachmentTypeDto[]) {
        this.id = attachment.id;
        this.attachmentType = attachmentTypeReferenceData.find((a) => a.value === attachment.attachmentType)!;
        this.attachmentTypeName = this.attachmentType!.name;
        this.attachmentTypeId = attachment.attachmentTypeId;
        this.attachmentTypeVersionNumber = attachment.attachmentTypeVersionNumber;
        this.attachmentTypeItemId = attachment.itemId;
        this.documentLink = attachment.documentLink;
        this.fileName = attachment.fileName;
        this.fileSize = attachment.fileSize;
        this.numberOfMappings = attachment.numberOfMappings;
        this.bulkLoadNumber = attachment.bulkLoadNumber?.toString();
        this.uploadedBy = attachment.uploadedBy;
        this.uploadedDate = attachment.uploadedDate;
        this.isSelected = false;
        this.dateLastModified = attachment.dateLastModified;
        this.relatedActivities = attachment.relatedActivities ?? [];
        this.relatedActivitiesString = attachment.relatedActivities?.map((activity) => `${activity.identifier} - ${activity.name}`).join("|");
    }

    public id: number;
    public attachmentType: Client.AdvancedSearchOperationAttachmentTypeDto;
    public attachmentTypeId: number;
    public attachmentTypeName: string;
    public attachmentTypeVersionNumber: number;
    public attachmentTypeItemId: string;
    public documentLink: string;
    public fileName: string;
    public fileSize: number;
    public numberOfMappings: number;
    public bulkLoadNumber: string | undefined;
    public uploadedBy: string | undefined;
    public uploadedDate: Date | undefined;
    public isSelected: boolean;
    public dateLastModified: Date;
    public relatedActivities: Client.GetGeoscienceAustraliaAttachmentListRelatedActivityDto[];
    public relatedActivitiesString: string | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.attachments = [];
        this.isResultSetLimited = false;
        this.advancedSearchReferenceData = {
            wellBoreholes: [],
            acquisitions: [],
            reprocessings: [],
            enos: [],
            searchTypes: [],
            searchOperations: [],
        };
        this.attachmentTypeReferenceData = [];

        this.searchText = "";

        this.statusMessages = undefined;

        this.canViewBoreholes = false;
        this.canViewAcquisitions = false;
        this.canViewProjects = false;
        this.canViewReports = false;
        this.canViewSurveyData = false;

        this.viewState = ViewStateEnum.Initialising;
    }

    public attachments: IAttachmentItemViewModel[];
    public isResultSetLimited: boolean;
    public searchText: string;
    public advancedSearchFilter?: IAdvancedSearchFilter | undefined;
    public advancedSearchReferenceData: IAdvancedSearchReferenceData;
    public attachmentTypeReferenceData: Client.AdvancedSearchOperationAttachmentTypeDto[];

    public statusMessages?: Client.StatusMessagesDto;

    public canViewBoreholes: boolean;
    public canViewAcquisitions: boolean;
    public canViewProjects: boolean;
    public canViewReports: boolean;
    public canViewSurveyData: boolean;

    public viewState: ViewStateEnum;

    public importAttachmentFile?: IImportAttachmentFile;

    public refreshList(response: Client.GetGeoscienceAustraliaAttachmentListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.attachments = response.items.map((i) => new AttachmentItemViewModel(i, vm.attachmentTypeReferenceData));
        vm.isResultSetLimited = response.isResultSetLimited;
        vm.statusMessages = undefined;
        vm.viewState = ViewStateEnum.View;
        vm.canViewBoreholes = response.permissions.canViewBoreholes;
        vm.canViewAcquisitions = response.permissions.canViewAcquisitions;
        vm.canViewProjects = response.permissions.canViewProjects;
        vm.canViewReports = response.permissions.canViewReports;
        vm.canViewSurveyData = response.permissions.canViewSurveyData;
        return vm;
    }

    public refreshSelectedAttachment(attachment: AttachmentItemViewModel): IRootViewModel {
        const vm = this._clone();

        vm.attachments = vm.attachments.map((r) => {
            if (r.id !== attachment.id) return r;
            return { ...r, isSelected: attachment.isSelected };
        });

        return vm;
    }

    public clearSelectedAttachments(): IRootViewModel {
        const vm = this._clone();

        vm.attachments = vm.attachments.map((r) => {
            return { ...r, isSelected: false };
        });

        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaAttachmentListReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.attachmentTypeReferenceData = response.attachmentTypes;
        vm.advancedSearchFilter = new AdvancedSearchFilter(response.searchTypes[0], response.searchOperations[0]);
        vm.advancedSearchReferenceData = {
            wellBoreholes: response.wellBoreholes,
            acquisitions: response.surveyAcquisitions,
            projects: response.reprocessingProjects,
            enos: response.enos,
            searchTypes: response.searchTypes,
            searchOperations: response.searchOperations,
        };
        return vm;
    }

    public refreshStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = statusMessages;
        return vm;
    }

    public refreshImportAttachmentFile(importAttachmentFile?: IImportAttachmentFile): IRootViewModel {
        const vm = this._clone();
        vm.importAttachmentFile = importAttachmentFile;
        return vm;
    }

    public changeSearchFilter(searchText: string, advancedSearchFilter: IAdvancedSearchFilter): IRootViewModel {
        const vm = this._clone();
        vm.searchText = searchText;
        vm.advancedSearchFilter = advancedSearchFilter;
        vm.viewState = ViewStateEnum.Searching;
        return vm;
    }

    public clearAllSearchFilters(): IRootViewModel {
        const vm = this._clone();
        vm.searchText = "";
        vm.advancedSearchFilter = new AdvancedSearchFilter(vm.advancedSearchReferenceData.searchTypes[0], vm.advancedSearchReferenceData.searchOperations[0]);
        vm.viewState = ViewStateEnum.Searching;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.advancedSearchFilter = clone(this.advancedSearchFilter);
        return vm;
    }
}
