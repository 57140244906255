// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    surveyData: Client.GetGeoscienceAustraliaSurveyDataDataAuditChangesResponseDto | undefined;

    refreshSurveyDataDataAuditChanges(response: Client.GetGeoscienceAustraliaSurveyDataDataAuditChangesResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    public surveyData: Client.GetGeoscienceAustraliaSurveyDataDataAuditChangesResponseDto | undefined;

    public refreshSurveyDataDataAuditChanges(response: Client.GetGeoscienceAustraliaSurveyDataDataAuditChangesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyData = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyData = clone(this.surveyData);
        return vm;
    }
}
