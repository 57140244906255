import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Forms from "../../common/forms/BootstrapForms";
import { IValidationModel } from "../../common/validation/ValidationModel";
import ValidationMessageView from "../../common/validation/ValidationMessageView";
import StatusMessagesAlertsView from "../../common/alerts/StatusMessagesAlertsView";
import { GetGeoscienceAustraliaReportAttachmentsItemDto, StatusMessagesDto } from "../../api/Client";

interface IProps {
    statusMessages: StatusMessagesDto | undefined;
    reportAttachment: GetGeoscienceAustraliaReportAttachmentsItemDto;
    publicTitle?: string;
    v: IValidationModel;
    onSave: () => void;
    onCancel: () => void;
    onChange: (updatedReportAttachment: GetGeoscienceAustraliaReportAttachmentsItemDto) => void;
}

export default function ReportAttachment(props: IProps): JSX.Element {
    const { statusMessages, publicTitle, v, onSave, onCancel, onChange } = props;

    const [state, setState] = useState(props.reportAttachment);

    function updateVmAndState(updatedState: GetGeoscienceAustraliaReportAttachmentsItemDto) {
        setState(updatedState);
        onChange(updatedState);
    }

    return (
        <Dialog title={`${state.id === undefined ? "Create" : "Update"} Report Attachment`} width={650} onClose={onCancel}>
            <StatusMessagesAlertsView statusMessages={statusMessages} />
            <Forms.Row>
                {publicTitle && (
                    <Forms.FullColumn>
                        <Forms.ReadonlyField label="Public Title" id="publicTitleField" value={publicTitle} />
                    </Forms.FullColumn>
                )}
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="File Name"
                        id="fileNameField"
                        value={state?.fileName}
                        maxLength={1000}
                        onChange={(value) => updateVmAndState(new GetGeoscienceAustraliaReportAttachmentsItemDto({ ...state, fileName: value }))}
                        valid={v.applyValidityForKendo("FileName")}
                    />
                    <ValidationMessageView name="FileName" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="Document Link"
                        id="documentLinkField"
                        value={state?.documentLink}
                        maxLength={1000}
                        onChange={(value) => updateVmAndState(new GetGeoscienceAustraliaReportAttachmentsItemDto({ ...state, documentLink: value }))}
                        valid={v.applyValidityForKendo("DocumentLink")}
                    />
                    <ValidationMessageView name="DocumentLink" validationModel={v} />
                </Forms.FullColumn>
            </Forms.Row>
            <DialogActionsBar>
                <button type="button" className="btn btn-primary me-2" onClick={onSave}>
                    {`${state?.id === undefined ? "Create" : "Update"}`}
                </button>
                <button type="button" className="btn btn-secondary me-2" onClick={onCancel}>
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
