// framework
import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// models
import { ISaveSurveyDataAttachment, IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import StatusMessagesAlertsView from "../../../../../../common/alerts/StatusMessagesAlertsView";
// redux
import * as Actions from "../../redux/actions";

interface IProps {
    vm: IRootViewModel;
    v: IValidationModel;
    actions: Actions.IActionFactory;
    onSave: () => void;
    onCancel: () => void;
}

export default function SurveyDataAttachment(props: IProps): JSX.Element {
    const { vm, v, actions, onSave, onCancel } = props;

    const [state, setState] = useState(vm.saveSurveyDataAttachment);

    function updateVmAndState(updatedState: ISaveSurveyDataAttachment) {
        setState(updatedState);
        actions.viewChanged(vm.onSaveSurveyDataAttachmentChanged(updatedState));
    }

    return (
        <Dialog title={`${state?.surveyDataAttachmentId === undefined ? "Create" : "Update"} Survey Data Attachment`} width={650} onClose={onCancel}>
            <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
            <Forms.Row>
                <Forms.FullColumn>
                    <Forms.ReadonlyField label="Public Title" id="publicTitleField" value={vm.publicTitle} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="File Name"
                        id="fileNameField"
                        value={state?.fileName}
                        maxLength={1000}
                        onChange={(value) => updateVmAndState({ ...state, fileName: value })}
                        valid={v.applyValidityForKendo("FileName")}
                    />
                    <ValidationMessageView name="FileName" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="Document Link"
                        id="documentLinkField"
                        value={state?.documentLink}
                        maxLength={1000}
                        onChange={(value) => updateVmAndState({ ...state, documentLink: value })}
                        valid={v.applyValidityForKendo("DocumentLink")}
                    />
                    <ValidationMessageView name="DocumentLink" validationModel={v} />
                </Forms.FullColumn>
            </Forms.Row>
            <DialogActionsBar>
                <button type="button" className="btn btn-primary me-2" onClick={onSave}>
                    {`${state?.surveyDataAttachmentId === undefined ? "Create" : "Update"}`}
                </button>
                <button type="button" className="btn btn-secondary me-2" onClick={onCancel}>
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
