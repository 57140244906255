import * as Client from "../../../api/Client";
import SimpleDataAuditDeltaAttributesView from "./SimpleDataAuditDeltaAttributesView";

interface IProps {
    delta: Client.SimpleDataAuditDeltaModelNodeDto | undefined;
}

export default function SimpleDataAuditDeltaNodeView(props: IProps) {
    if (!props.delta) return <></>;
    const { delta } = props;

    let backgroundColor = "";
    let changeType = "Unknown";
    let message = "";
    if (delta.changeType === Client.DeltaModelChangeTypeEnum.Created) {
        backgroundColor = "#e6ffe6"; // a light green
        changeType = "Created";
    } else if (delta.changeType === Client.DeltaModelChangeTypeEnum.Updated) {
        backgroundColor = "#ffffcc"; // a light yellow
        changeType = "Updated";
    } else if (delta.changeType === Client.DeltaModelChangeTypeEnum.Deleted) {
        backgroundColor = "#ffcccc"; // a light red
        changeType = "Deleted";
    }
    if (delta.key) message = `${delta.name} - ${delta.key} - ${changeType}`;
    else message = `${delta.name} - ${changeType}`;
    const style = { backgroundColor: backgroundColor };

    return (
        <>
            <div className="card mb-2" style={style}>
                <div className="card-body px-4">
                    <h5 className="card-title">{message}</h5>
                    <SimpleDataAuditDeltaAttributesView originalAttributes={delta.originalAttributes} newAttributes={delta.newAttributes} />
                    {delta.nodes &&
                        delta.nodes.map((node, index) => {
                            return (
                                <div key={index.toString()}>
                                    <SimpleDataAuditDeltaNodeView delta={node} />
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
}
