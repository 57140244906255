import { IRootViewModel } from "../../models/models";
import ReportAttachments from "../../../../../../common/reportAttachments/ReportAttachmentsControl";
import { GetGeoscienceAustraliaReportAttachmentsItemDto } from "../../../../../../api/Client";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import LinksView, { LinkDetail } from "../../../../../common/links/LinksView";

interface IProps {
    vm: IRootViewModel;
    onEdit: (reportId: number, reportVersionNumber: number, reportAttachmentId: number, documentLink: string, fileName: string) => void;
    onDelete: (reportId: number, reportVersionNumber: number, reportAttachmentId: number) => void;
    onBatchDownload?: (reportAttachments: GetGeoscienceAustraliaReportAttachmentsItemDto[]) => void;
}

export default function ReprocessingProjectReportAttachmentsView(props: IProps): JSX.Element {
    const { vm, onEdit, onDelete, onBatchDownload } = props;

    return (
        <>
            <LinksView
                title="Reprocessing"
                canViewLink={vm.canViewReprocessings}
                linkTooltip="Click to view Reprocessing Attachments."
                links={[new LinkDetail(vm.reprocessingName, GeoscienceAustraliaRouteHelper.reprocessingAttachments(vm.reprocessingId!))]}
            />
            <ReportAttachments attachments={vm.attachments} statusMessages={vm.statusMessages} onEdit={onEdit} onDelete={onDelete} onBatchDownload={onBatchDownload}></ReportAttachments>
        </>
    );
}
