// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    Acquisitions,
    Titles,
    DataMonitoring,
    Reports,
    Attachments,
    Parameters,
    SurveyData,
    Auditing,
}

export interface IProps {
    surveyId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.surveyDetails(props.surveyId),
                },
                {
                    key: TabsEnum.Acquisitions,
                    title: "Acquisitions",
                    url: geoscienceAustraliaRouteHelper.surveyAcquisitions(props.surveyId),
                },
                {
                    key: TabsEnum.Titles,
                    title: "Titles",
                    url: geoscienceAustraliaRouteHelper.surveyTitles(props.surveyId),
                },
                {
                    key: TabsEnum.DataMonitoring,
                    title: "Data Monitoring",
                    url: geoscienceAustraliaRouteHelper.surveyDataMonitoring(props.surveyId),
                },
                {
                    key: TabsEnum.SurveyData,
                    title: "Survey Data",
                    url: geoscienceAustraliaRouteHelper.surveySurveyData(props.surveyId),
                },
                {
                    key: TabsEnum.Parameters,
                    title: "Parameters",
                    url: geoscienceAustraliaRouteHelper.surveyParameters(props.surveyId),
                },
                {
                    key: TabsEnum.Reports,
                    title: "Reports",
                    url: geoscienceAustraliaRouteHelper.surveyReports(props.surveyId),
                },
                {
                    key: TabsEnum.Attachments,
                    title: "Attachments",
                    url: geoscienceAustraliaRouteHelper.surveyReportAttachments(props.surveyId),
                },
                {
                    key: TabsEnum.Auditing,
                    title: "Auditing",
                    url: geoscienceAustraliaRouteHelper.surveyAuditing(props.surveyId),
                },
            ]}
        />
    );
}
