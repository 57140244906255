// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    report: Client.GetGeoscienceAustraliaReportDataAuditChangesResponseDto | undefined;

    refreshReportDataAuditChanges(response: Client.GetGeoscienceAustraliaReportDataAuditChangesResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    public report: Client.GetGeoscienceAustraliaReportDataAuditChangesResponseDto | undefined;

    public refreshReportDataAuditChanges(response: Client.GetGeoscienceAustraliaReportDataAuditChangesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.report = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.report = clone(this.report);
        return vm;
    }
}
