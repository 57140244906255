// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    dataSubmission: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataAuditChangesResponseDto | undefined;

    refreshDataSubmissionDataAuditChanges(response: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataAuditChangesResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    public dataSubmission: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataAuditChangesResponseDto | undefined;

    public refreshDataSubmissionDataAuditChanges(response: Client.GetGeoscienceAustraliaDataSubmissionDataSubmissionDataAuditChangesResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.dataSubmission = response;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.dataSubmission = clone(this.dataSubmission);
        return vm;
    }
}
