import * as Client from "../../../api/Client";
import SimpleDataAuditSnapshotAttributesView from "./SimpleDataAuditSnapshotAttributesView";

interface IProps {
    snapshot: Client.SimpleDataAuditSnapshotModelNodeDto | undefined;
}

export default function SimpleDataAuditSnapshotNodeView(props: IProps) {
    if (!props.snapshot) return <></>;
    const { snapshot } = props;

    const style = { backgroundColor: "#e6ffe6" }; // a light green
    let message = "";
    if (snapshot.key) message = `${snapshot.name} - ${snapshot.key} - Created`;
    else message = `${snapshot.name} - Created`;

    return (
        <>
            <div className="card mb-2" style={style}>
                <div className="card-body px-4">
                    <h5 className="card-title">{message}</h5>
                    <SimpleDataAuditSnapshotAttributesView attributes={snapshot.attributes} />
                    {snapshot.nodes &&
                        snapshot.nodes.map((node, index) => {
                            return (
                                <div key={index.toString()}>
                                    <SimpleDataAuditSnapshotNodeView snapshot={node} />
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
}
