// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    Lines,
    FileAssets,
    Attachments,
    RelatedActivities,
    Auditing,
}

export interface IProps {
    surveyDataId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.surveyDataDetails(props.surveyDataId),
                },
                {
                    key: TabsEnum.Lines,
                    title: "Lines",
                    url: geoscienceAustraliaRouteHelper.surveyDataLines(props.surveyDataId),
                },
                {
                    key: TabsEnum.FileAssets,
                    title: "File/Asset",
                    url: geoscienceAustraliaRouteHelper.surveyDataFileAssets(props.surveyDataId),
                },
                {
                    key: TabsEnum.Attachments,
                    title: "Attachments",
                    url: geoscienceAustraliaRouteHelper.surveyDataAttachments(props.surveyDataId),
                },
                {
                    key: TabsEnum.RelatedActivities,
                    title: "Related Activities",
                    url: geoscienceAustraliaRouteHelper.surveyDataRelatedActivities(props.surveyDataId),
                },
                {
                    key: TabsEnum.Auditing,
                    title: "Auditing",
                    url: geoscienceAustraliaRouteHelper.surveyDataAuditing(props.surveyDataId),
                },
            ]}
        />
    );
}
