// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    View = "geoscienceAustralia.wellsBoreholes.surveyDataDetails.models.viewState.view",
    Edit = "geoscienceAustralia.wellsBoreholes.surveyDataDetails.models.viewState.edit",
    Delete = "geoscienceAustralia.wellsBoreholes.surveyDataDetails.models.viewState.delete",
}

export interface ISurveyDataDetails {
    title?: string | undefined;
    publicTitle?: string | undefined;
    itemId?: string | undefined;
    surveyDataType?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataSurveyDataType | undefined;
    isCopiedToNopims?: boolean | undefined;
    isConfidential?: boolean | undefined;
    tapeId?: string | undefined;
    surveyDataFormat?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataSurveyDataType | undefined;
    confidentialityRemarks?: string | undefined;
    basicInterpType?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataBasicInterpTypes | undefined;

    receivedDate?: Date | undefined;
    writtenDate?: Date | undefined;
    basicRelevantDate?: Date | undefined;
    interpRelevantDate?: Date | undefined;
    releasedDate?: Date | undefined;

    company?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataContractorCompanies | undefined;
    contractor?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataContractorCompanies | undefined;
    inlineRange?: string | undefined;
    crossLineRange?: string | undefined;
}

export interface IReferenceData {
    surveyDataTypes: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataSurveyDataType[];
    surveyDataFormat: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataSurveyDataType[];
    contractorCompanies: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataContractorCompanies[];
    basicInterpTypes: Client.GetGeoscienceAustraliaReportDetailsReferenceDataBasicInterpTypes[];
}

export interface IRootViewModel {
    surveyDataId: number | undefined;
    surveyDataVersionNumber: number | undefined;
    pageHeader: string | undefined;

    surveyDataDetails: ISurveyDataDetails;
    referenceData: IReferenceData;

    auditDto?: Client.SimpleAuditEventsDto;

    statusMessages?: Client.StatusMessagesDto;

    isDirty: boolean;
    isConflict: boolean;
    viewState: ViewStateEnum;

    refreshDetails(response: Client.GetGeoscienceAustraliaSurveyDataDetailsResponseDto): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataResponseDto): IRootViewModel;
    refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaSurveyDataDetailsResponseDto): IRootViewModel;
    refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaSurveyDataResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;

    onEditState(): IRootViewModel;

    onSurveyDataDetailsChanged(surveyDataDetails: ISurveyDataDetails): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyDataId = undefined;
        this.surveyDataVersionNumber = undefined;
        this.pageHeader = undefined;

        this.surveyDataDetails = {};
        this.referenceData = {
            surveyDataTypes: [],
            surveyDataFormat: [],
            contractorCompanies: [],
            basicInterpTypes: [],
        };

        this.isDirty = false;
        this.isConflict = false;
        this.viewState = ViewStateEnum.View;
    }

    public surveyDataId: number | undefined;
    public surveyDataVersionNumber: number | undefined;
    public pageHeader: string | undefined;

    public surveyDataDetails: ISurveyDataDetails;
    public referenceData: IReferenceData;

    public auditDto?: Client.SimpleAuditEventsDto;

    public statusMessages?: Client.StatusMessagesDto;

    public isDirty: boolean;
    public isConflict: boolean;
    public viewState: ViewStateEnum;

    public refreshDetails(response: Client.GetGeoscienceAustraliaSurveyDataDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyDataId = response.surveyDataId;
        vm.surveyDataVersionNumber = response.surveyDataVersionNumber;
        vm.pageHeader = response.surveyDataHeader;

        vm.surveyDataDetails = {
            title: response.title,
            publicTitle: response.publicTitle,
            itemId: response.itemId,
            surveyDataType: vm.referenceData.surveyDataTypes.find((rt) => rt.id === response.surveyDataTypeId),
            isCopiedToNopims: response.isCopiedToNopims,
            isConfidential: response.isConfidential,
            tapeId: response.tapeId,
            surveyDataFormat: vm.referenceData.surveyDataFormat.find((rt) => rt.id === response.surveyDataDataFormatId),
            confidentialityRemarks: response.confidentialityRemarks,
            receivedDate: response.receivedDate,
            writtenDate: response.writtenDate,
            basicRelevantDate: response.basicRelevantDate,
            interpRelevantDate: response.interpRelevantDate,
            releasedDate: response.releasedDate,
            company: vm.referenceData.contractorCompanies.find((c) => c.id === response.companyId)!,
            contractor: vm.referenceData.contractorCompanies.find((c) => c.id === response.contractorId)!,
            inlineRange: response.inlineRange,
            crossLineRange: response.crossLineRange,
            basicInterpType: vm.referenceData.basicInterpTypes.find((t) => t.value === response.basicInterpType)!,
        };

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;
        vm.auditDto = response.surveyDataAuditDto;

        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            surveyDataTypes: response.surveyDataTypes,
            surveyDataFormat: response.surveyDataFormat,
            contractorCompanies: response.contractorCompanies,
            basicInterpTypes: response.basicInterpTypes,
        };
        return vm;
    }

    public refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaSurveyDataDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    public refreshDeleteResponse(response: Client.DeleteGeoscienceAustraliaSurveyDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;

        if (response.statusMessages?.isSuccess) {
            vm.viewState = ViewStateEnum.Delete;
        }
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public onEditState(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.statusMessages = undefined;
        return vm;
    }

    public onSurveyDataDetailsChanged(surveyDataDetails: ISurveyDataDetails): IRootViewModel {
        const vm = this._clone();
        vm.surveyDataDetails = surveyDataDetails;
        vm.isDirty = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyDataDetails = clone(this.surveyDataDetails);
        vm.referenceData = clone(this.referenceData);
        return vm;
    }
}
