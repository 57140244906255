import * as Client from "../../../api/Client";
import { orderBy } from "@progress/kendo-data-query";

interface IProps {
    attributes?: Client.SnapshotModelAttributeDto[] | undefined;
}

export default function SimpleDataAuditSnapshotAttributesView(props: IProps) {
    if (!props.attributes || props.attributes.length === 0) return <></>;

    const attributes = orderBy(props.attributes, [{ field: "name", dir: "asc" }]);

    return (
        <>
            <div className="table-responsive">
                <table className="table table-bordered table-striped">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" className="w-25">
                                Attribute
                            </th>
                            <th scope="col" className="w-75">
                                Value
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-light">
                        {attributes.map((attribute, i) => (
                            <tr key={i.toString()}>
                                <td>{attribute.name}</td>
                                <td>{attribute.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
