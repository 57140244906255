// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    Auditing,
}

export interface IProps {
    authorisationId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.authorisationDetails(props.authorisationId),
                },
                {
                    key: TabsEnum.Auditing,
                    title: "Auditing",
                    url: geoscienceAustraliaRouteHelper.authorisationAuditing(props.authorisationId),
                },
            ]}
        />
    );
}
